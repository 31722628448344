import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  Container
} from "reactstrap";
import BreadCrumb from "../../../components/Common/BreadCrumb";
import { IDomain } from "api/types/_domain";

import { getAllDomains } from "store/domain/thunk";
import CampaignForm from "./CampaignForm";

const CampaignCreate = () => {
  const { t, i18n } = useTranslation();

  const [listDomain, setListDomain] = useState<IDomain[]>([]);

  const handleCallAllOption = async () => {
    try {
      const [resDomain]: any = await Promise.all([getAllDomains()]);
      if (resDomain) {
        const res = resDomain?.data?.list?.map((item: any, index: number) => ({
          id: item?.id,
          name: item?.name,
          website: item?.domain,
        }));
        setListDomain((_prev) => res);
      }
    } catch (error: any) {
      return error;
    }
  };

  useEffect(() => {
    handleCallAllOption();
  }, []);

  useEffect(() => {
    document.title = `${t("Create Campaign")} - ${t("Campaign")} | MessageHub`;
    document.body.classList.remove("vertical-sidebar-enable");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [i18n?.language]);


  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb
            title={t("Create Campaign")}
            pageTitle={t("Campaign")}
          />
          <CampaignForm
            listDomain={listDomain}
          />
        </Container>
      </div>
    </React.Fragment>
  );
};

export default CampaignCreate;
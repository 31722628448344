import React, { useEffect, } from "react";
import { useTranslation } from "react-i18next";
import BreadCrumb from "../../../components/Common/BreadCrumb";
import { Container, Row, } from "reactstrap";

import 'react-toastify/dist/ReactToastify.css';


const CampaignLogList = () => {
    const { t, i18n } = useTranslation();

    useEffect(() => {
        document.title = `${t('Campaign Log')} - ${t('Statistics')} | MessageHub`;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [i18n?.language]);

    return (
        <React.Fragment>
            <div className="page-content">

                <Container fluid>
                    <BreadCrumb title={t('Campaign Log')} pageTitle={t('Statistics')} />
                    <Row>
                        <div className='text-center mt-5'>
                            {t('Under development')}
                        </div>
                    </Row>
                </Container>
            </div>
        </React.Fragment >
    );
};

export default CampaignLogList;
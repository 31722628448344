import { Navigate } from "react-router-dom";

//Dashboard
import DashboardCrm from "../pages/Dashboard";


//login
import ForgetPasswordPage from "../pages/Authentication/ForgetPassword";
import Login from "../pages/Authentication/Login";
import Logout from "../pages/Authentication/Logout";
import Register from "../pages/Authentication/Register";

// User Profile
import UserList from "pages/User/User";
import RoleList from "pages/User/Role";
import Profile from "pages/User/Profile";
// import ClickLogList from "pages/Statistics/ClickLog";

// Campaign
import CampaignList from "pages/Campaign/CampaignList";
import CampaignCreate from "pages/Campaign/CampaignForm";

// Media
import MediaList from "pages/Media/Media/MediaList";
import MediaCreate from "pages/Media/Media/MediaForm";

// Device
import DeviceList from "pages/Media/Device";

import NotificationLogList from "pages/Statistics/NotificationLog";
import CampaignLogList from "pages/Statistics/CampaignLog";
import MediaLogList from "pages/Statistics/MediaLog";


const authProtectedRoutes = [
  { path: "/dashboard", component: <DashboardCrm /> },

  { path: "/user/list", component: <UserList /> },

  { path: "/user/role", component: <RoleList /> },

  { path: "/user/profile", component: <Profile /> },

  // { path: "/statistics/click-log", component: <ClickLogList /> },

  { path: "campaign/list", component: <CampaignList /> },

  { path: "campaign/create", component: <CampaignCreate /> },

  { path: "/media/list", component: <MediaList /> },
  { path: "/media/create", component: <MediaCreate /> },

  { path: "/media/subscription", component: <DeviceList /> },

  { path: "/statistics/notification-log", component: <NotificationLogList /> },

  { path: "/statistics/campaign-log", component: <CampaignLogList /> },

  { path: "/statistics/media-log", component: <MediaLogList /> },


  // eslint-disable-next-line react/display-name
  {
    path: "/",
    exact: true,
    component: <Navigate to="/dashboard" />,
  },
  { path: "*", component: <Navigate to="/dashboard" /> },
];

const publicRoutes = [
  // Authentication Page
  { path: "/logout", component: <Logout /> },
  { path: "/login", component: <Login /> },
  { path: "/forgot-password", component: <ForgetPasswordPage /> },
  { path: "/register", component: <Register /> },
];

export { authProtectedRoutes, publicRoutes };

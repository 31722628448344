import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from 'reactstrap';

//import images
import avatar1 from "../../assets/images/users/user-dummy-img.jpg";
import { createSelector } from 'reselect';
import { useTranslation } from 'react-i18next';
import { removeAccessToken, removeRefreshToken } from 'helpers/cookie';
import { push } from 'helpers/navigation';

const ProfileDropdown = () => {

    const { t } = useTranslation();
    // Inside your component
    const user: any = useSelector((state: any) => state?.Login);


    const [userName, setUserName] = useState("Admin");
    const [roleName, setRoleName] = useState("");

    useEffect(() => {
        let authUser: any = localStorage.getItem("authUser")
        if (localStorage.getItem("authUser")) {
            const obj = JSON.parse(authUser);
            setUserName((obj?.user?.first_name || 'Admin') + ' ' + (obj?.user?.last_name || ''));
            setRoleName(obj?.user?.role?.name || 'Admin');
        }
    }, [user]);

    //Dropdown Toggle
    const [isProfileDropdown, setIsProfileDropdown] = useState<boolean>(false);
    const toggleProfileDropdown = () => {
        setIsProfileDropdown(!isProfileDropdown);
    };

    return (
        <React.Fragment>
            <Dropdown isOpen={isProfileDropdown} toggle={toggleProfileDropdown} className="ms-sm-3 header-item topbar-user">
                <DropdownToggle tag="button" type="button" className="btn">
                    <span className="d-flex align-items-center">
                        <img className="rounded-circle header-profile-user" src={avatar1}
                            alt="Header Avatar" />
                        <span className="text-start ms-xl-2">
                            <span className="d-none d-xl-inline-block ms-1 fw-medium user-name-text">{userName}</span>
                            <span className="d-none d-xl-block ms-1 fs-12 text-muted user-name-sub-text">{roleName}</span>
                        </span>
                    </span>
                </DropdownToggle>
                <DropdownMenu className="dropdown-menu-end">
                    <h6 className="dropdown-header">{t('Welcome')} {userName}!</h6>
                    <DropdownItem className='p-0'>
                        <Link to="/user/profile" className="dropdown-item">
                            <i className="mdi mdi-account-circle text-muted fs-16 align-middle me-1"></i>
                            <span className="align-middle">{t('Profile')} </span>
                        </Link>
                    </DropdownItem>
                    <DropdownItem className='p-0'>
                        <Link to="#" className="dropdown-item" onClick={(e) => {
                            e.preventDefault();
                            localStorage.removeItem("authUser");
                            removeAccessToken();
                            removeRefreshToken();
                            push("/login");
                        }}>
                            <i
                                className="mdi mdi-logout text-muted fs-16 align-middle me-1"></i> <span
                                    className="align-middle" data-key="t-logout">{t('Button Logout')}</span>
                        </Link>
                    </DropdownItem>
                </DropdownMenu>
            </Dropdown>
        </React.Fragment>
    );
};

export default ProfileDropdown;
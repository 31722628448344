import { AxiosResponse } from "axios";
import { APIClient } from "./axiosClient";
import { IDomain } from "./types/_domain";
import { PaginationResponse, ResponseData } from "./types/_public";
const api = new APIClient();

const path = '/medias';
const DomainApi = {
  domains(params: any):Promise<AxiosResponse<ResponseData<IDomain[]> & PaginationResponse, any>> {
    const url = `${path}`
    return api.get(url, params);
  },
  allDomains(params: any):Promise<AxiosResponse<ResponseData<IDomain[]> & PaginationResponse, any>> {
    const url = `${path}/get-all`
    return api.get(url, params)
  },
  getDomain(id: string | number, params: any = {}) : Promise<AxiosResponse<ResponseData<IDomain>, any>>  {
    const url = `${path}/${id}`;
    return api.get(url, params)
  },
  postDomain(dataForm: any) : Promise<AxiosResponse<ResponseData<IDomain>, any>>  {
    const url = `${path}`;
    return api.post(url, dataForm)
  },
  putDomain(id: string | number, dataForm: any) : Promise<AxiosResponse<ResponseData<IDomain>, any>>  {
    const url = `${path}/${id}`;
    return api.post(url, dataForm);
  },
  deleteDomain(id: string | number, dataForm: any) : Promise<AxiosResponse<ResponseData<IDomain>, any>>  {
    const url = `${path}/${id}`;
    return api.delete(url, dataForm)
  },

  renderScriptTrackingNews(id: string | number, params: any = {}):Promise<AxiosResponse<ResponseData<any>, any>> {
    const url = `${path}/${id}`
    return api.get(url, params)
  },
}


export default DomainApi

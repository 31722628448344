import { createSlice } from "@reduxjs/toolkit";
import { IDomain } from "api/types/_domain";
import { PaginationResponse, ResponseData } from "api/types/_public";
import { getDomains } from "./thunk";


export interface IState {
  domains: ResponseData<IDomain[]> & PaginationResponse | null,
  isDomainLoading: boolean,
  isDomainSuccess: boolean,

  allDomain: ResponseData<IDomain[]> | null,

  error: any,
};

export const initialState: IState = {
  domains: null,
  isDomainLoading: false,
  isDomainSuccess: false,

  allDomain: null,

  error: {},
};

const DomainSlice = createSlice({
  name: "Domain",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    //get Domains
    builder.addCase(getDomains.pending, (state: IState, action: any) => {
      state.isDomainLoading = true
    });
    builder.addCase(getDomains.fulfilled, (state: IState, action: any) => {
      state.domains = action.payload.data;
      state.isDomainSuccess = true;
      state.isDomainLoading = false;
    });
    builder.addCase(getDomains.rejected, (state: IState, action: any) => {
      state.error = action.payload.error || null;
      state.isDomainSuccess = false;
      state.isDomainLoading = false;
    });
  },
});

export default DomainSlice.reducer;
import { IDomain } from "api/types/_domain";
import { Option } from "api/types/_public";
import {
    CONFIG_OPTION_TOAST_ERROR,
    CONFIG_OPTION_TOAST_NORMAL,
} from "common/toast";
import en from "date-fns/locale/en-US";
import ko from "date-fns/locale/ko";
import { useFormik } from "formik";
import {
    SCHEDULE_MONTHLY_OPTIONS,
    SCHEDULE_OPTIONS,
    SCHEDULE_WEEKLY_OPTIONS
} from "helpers/constans";
import React, { useEffect, useState } from "react";
import { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useTranslation } from "react-i18next";
import "react-quill/dist/quill.snow.css";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
    Col,
    Label,
    Spinner,
    Row
} from "reactstrap";
import {
    postDomain, putDomain, uploadFiles
} from "store/thunks";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { createSelector } from "reselect";

import Dropzone from 'react-dropzone';
import _cloneDeep from 'lodash/cloneDeep'

import './MediaFormStyled.scss'

export interface Tag {
    id: string;
    text: string;
}

export interface Props {
    isModal?: boolean;
    id?: string;
    isCopy?: boolean,
    triggerRefresh?: () => void;
    data?: any
    isUpdate?: boolean
    close?: () => void;
}

registerLocale("en", en);
registerLocale("ko", ko);

const MediaForm = ({
    isModal = false,
    id = '',
    triggerRefresh,
    data = null,
    isCopy = false, close
}: Props) => {
    const { t, i18n } = useTranslation();
    const navigate = useNavigate();
    // const { id = "" } = useParams();
    const dispatch: any = useDispatch();

    const SCHEDULE_OPTIONS_LANG = SCHEDULE_OPTIONS?.map((item: any) => ({
        value: item?.value,
        label: t(item?.label),
    }));

    const OPTIONS_MUST_HAVE_DATE = ["weekly", "monthly"];

    const MONTHLY_OPTIONS =
        SCHEDULE_MONTHLY_OPTIONS?.map((item: Option) => ({
            ...item,
            type: "monthly",
        })) || [];
    const WEEKLY_OPTIONS =
        SCHEDULE_WEEKLY_OPTIONS?.map((item: Option) => ({
            ...item,
            type: "weekly",
        })) || [];
    const DATE_OPTIONS_LANG = MONTHLY_OPTIONS.concat(WEEKLY_OPTIONS)?.map(
        (item: any) => ({ ...item, label: t(item?.label) })
    );

    const [isLoading, setIsLoading] = useState<boolean>(false);

    const [image, setImage] = useState<any>(null);

    const selectUploadState = (state: any) => state.Upload;

    const UploadProperties = createSelector(
        selectUploadState,
      (state) => ({
        files: state.files,
        isFilesSuccess: state.isFilesSuccess,
        isFilesLoading: state.isFilesLoading,
        error: state.error,
      })
    );
  
    const {
        files,
        isFilesSuccess,
        isFilesLoading,
        error
    } = useSelector(UploadProperties);

    const handleShowConfirmCreateCampaign = () => {
        handleSubmit(formik.values);
    };

    const handleSubmit = async (values: any) => {

        try {
            setIsLoading((_prev) => true);
            const data: any = {
                domain: values?.domain,
                name: values?.name,
                description: values?.description,
                script_url: values?.script_url,
                icon_url: image
            }

            if (values?.list_domain && values?.list_domain.length > 0) {
                const listDomainNew: string = [values?.domain, ...values?.list_domain.map((item: any) => item.domain)].join(',');
                data.domain = listDomainNew
            }

            const response: any = id ? await putDomain(id, data) : await postDomain(data);
            if (response?.code === 200) {
                setIsLoading((_prev) => false);
                toast(
                    `${t("The process has been completed.")}`,
                    CONFIG_OPTION_TOAST_NORMAL
                );
                triggerRefresh && triggerRefresh();
            } else {
                setIsLoading((_prev) => false);
                toast(`${response}`, CONFIG_OPTION_TOAST_ERROR);
            }
        } catch (error: any) {
            setIsLoading((_prev) => false);
            toast(`${error?.message || ""}`, CONFIG_OPTION_TOAST_ERROR);
            return error;
        }
    };


    const validationSchema = Yup.object({
        domain: Yup.string().matches(/((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/, `${t('Enter correct Domain URL')}`).required(`${t('Domain is required')}`),
        name: Yup.string().required(`${t("Name is required")}`),
        script_url: Yup.string()
            .matches(/((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/, `${t('Enter correct Script URL')}`)
            .required(`${t('Script URL is required')}`),
        list_domain: Yup.array().of(
            Yup.object().shape({
                domain: Yup.string()
                    .matches(/((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/, `${t('Enter correct Domain URL')}`)
                    .required(`${t('Domain is required')}`),
            })
        ),
    });

    const formik = useFormik({
        initialValues: {
            domain: "",
            name: "",
            description: "",
            image: null,
            script_url: "",
            list_domain: []
        },
        validationSchema,
        onSubmit: handleShowConfirmCreateCampaign
    });

    const setDataFrom = () => {
        if (data && data.id) {
            const { domain, name, description, script_url, icon_url } = data;

            const valueUpdate: any = {
                name,
                description,
                image: icon_url,
                script_url
            }

            if (domain) {
                const domainConvertArr = domain.split(',')
                if (domainConvertArr && domainConvertArr.length === 1) {
                    valueUpdate.domain = domainConvertArr[0]
                }
                if (domainConvertArr && domainConvertArr.length > 1) {
                    valueUpdate.domain = domainConvertArr[0]
                    const listDomainConvertArrClone = _cloneDeep(domainConvertArr);
                    listDomainConvertArrClone.shift();
                    const listDomain = listDomainConvertArrClone.map((value: any, index: any) => {
                        return { id: index, domain: value }
                    })
                    valueUpdate.list_domain = _cloneDeep(listDomain)
                }

            } else {
                valueUpdate.domain = '';
            }

            formik.setValues({ ...formik.values, ...valueUpdate })
            setImage(icon_url)
        }

    }

    useEffect(() => {
        setDataFrom()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data])


    useEffect(() => {
        // document.title = `${t("Campaign Create")} - ${t("Campaign")} | MessageHub`;
        document.body.classList.remove("vertical-sidebar-enable");
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [i18n?.language]);

    const handleReset = () => {
        if (!!id) {
            setDataFrom()

        }
        else {
            formik.handleReset({})
        }
    };


    const handleDrop = async (dropped: any) => {
        const getLogo = dropped.map((file: Blob | MediaSource) => Object.assign(file, {
            preview: URL.createObjectURL(file)
        }))

        if (Array.isArray(getLogo) && getLogo.length > 0) {

            const images = await dispatch(uploadFiles(getLogo[0]))
            if (images.payload && images?.payload?.code === 200 && images?.payload?.data && Array.isArray(images?.payload?.data) && images?.payload?.data.length > 0) {
                setImage(images?.payload?.data[0]?.url)
            }
        }
    }

    const checkImageFormat = (filename: any) => {
        if (filename) {
          const fileExtension = filename.split('.').pop().toLowerCase();
    
          if (fileExtension === 'png') {
            return '#eaeaea'
          } else {
            return 'transparent';
          }
        }
        else return 'transparent'
      }

    const removeImage = (event: any) => {
        formik.setFieldValue('image', null)
        setImage(null)
    }

    const handleOnAddDomain = (event: any) => {
        event.preventDefault()
        const listDomain = formik?.values?.list_domain;
        const listDomainClone: any[] = _cloneDeep(listDomain)
        listDomainClone.push({ id: listDomain.length, domain: '' })
        formik.setFieldValue('list_domain', listDomainClone)
    }

    const handleOnRemoveDomain = (event: any, value: any) => {
        event.preventDefault()
        if (value) {

            const listDomain = formik?.values?.list_domain;
            const listDomainRemove = listDomain.filter((item: any) => {

                return (
                    item.id !== value.id
                )

            });

            formik.setFieldValue('list_domain', listDomainRemove)
        }
    }

    const handleOnChangeListDomain = (event: any, value: any) => {
        event.preventDefault()

        const listDomain = formik?.values?.list_domain;
        const listDomainUpdate: any[] = listDomain.map((item: any) => {
            if (item.id === value.id) {
                return { ...item, domain: event?.target?.value || '' }
            } else {
                return { ...item }
            }
        })

        formik.setFieldValue('list_domain', listDomainUpdate)
    }

    return (
        <React.Fragment>
            <form onSubmit={formik.handleSubmit} onReset={handleReset}>
                <div className="row g-3">

                    <Col md={12} lg={6}>
                        <label className="form-label">{t('Icon')}</label>
                        <div className="cursor-pointer" style={{ border: '2px dashed #25a0e2', height: '140px', width: '140px', borderRadius: '20px',position: 'relative' }}>
                            <Dropzone
                                onDrop={handleDrop}
                                noKeyboard
                            >
                                {({ getRootProps, getInputProps }) => (
                                    <div className="w-100 h-100 position-relative" {...getRootProps()}>
                                        {(image || data?.logo) && !isFilesLoading ?
                                            (
                                                <>
                                                    <div className="d-flex h-100 justify-content-center align-items-center flex-column text-center">
                                                        <div className="d-flex w-100 h-100" style={{ borderRadius: '20px', backgroundColor: checkImageFormat(image || data?.logo) }}>
                                                            <div className="d-flex w-100 align-items-center">
                                                                <img alt=""
                                                                    style={{ maxHeight: '140px', maxWidth: '140px', height: 'auto', width: '100%', padding: '8px', borderRadius: '16px' }}
                                                                    src={image || data?.logo}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <Label htmlFor="profile-img-file-input" style={{ position: 'absolute', bottom: '-24px', right: '-12px' }}
                                                        className="profile-photo-edit avatar-xs">
                                                        <span className="avatar-title rounded-circle bg-light text-body">
                                                            <i className="ri-camera-fill"></i>
                                                        </span>
                                                    </Label>
                                                </>
                                            )
                                            : 
                                            <>
                                                <div className={`position-absolute top-50 start-50 translate-middle ${isFilesLoading ? 'd-block'  : 'd-none'}`}>
                                                    <Spinner style={{
                                                        height: '3rem',
                                                        width: '3rem'
                                                    }} color="primary"
                                                    ></Spinner>
                                                </div>

                                                <div className={`d-flex h-100 justify-content-center align-items-center flex-column text-center ${isFilesLoading ? 'file-uploading' : ''}`}>
                                                    <i style={{ fontSize: '20px' }} className="d-flex bx bx-image-add" />
                                                    <h6 className="fs-10 mt-2">
                                                        {t('Click or')}
                                                    </h6>
                                                    <h6 className="fs-10">
                                                        {t('Drag to upload the Icon')}
                                                    </h6>
                                                    <span className="fs-10">
                                                        <span className="fw-bold">JPG</span>
                                                        {', '}
                                                        <span className="fw-bold">PNG</span>
                                                        {', '}
                                                        <span className="fw-bold"> SVG</span>
                                                    </span>
                                                </div>
                                            </>
                                        }
                                        <input {...getInputProps()} />
                                    </div>
                                )}
                            </Dropzone>

                            {(image || data?.logo) && <Label style={{ position: 'absolute', top: '-10px', right: '-12px', cursor: 'pointer' }}
                                className="profile-photo-edit avatar-xs" onClick={removeImage}>
                                <span className="avatar-title rounded-circle bg-light text-body">
                                    <i className="mdi mdi-delete"></i>
                                </span>
                            </Label>}
                        </div>
                    </Col>


                    <Col xxl={12}>
                        <label className="form-label">{t('Name')}<span className="text-danger"> *</span></label>
                        <input
                            className="form-control"
                            id="name"
                            name="name"
                            value={formik.values?.name}
                            placeholder={`${t('Enter Name')}...`}
                            onChange={(event: any) => formik.setFieldValue('name', event?.target?.value || '')}
                        />
                        {formik.touched.name && formik.errors.name ? (
                            <div className="text-danger mt-2">
                                {formik.errors.name}
                            </div>
                        ) : null}
                    </Col>

                    <Col xl={12} className="d-flex flex-column" style={{ gap: '8px' }}>
                        <Row key='domain' style={{
                            borderRadius: '5px',
                            border: '1px dashed var(--vz-info)',
                            backgroundColor: 'var(--vz-light)',
                            margin: '8px 0px',
                            padding: '8px 0px'
                        }} className="position-relative">
                            <div className="position-absolute w-auto end-0 top-0"
                                style={{
                                    transform: 'translateY(-50%)',
                                    padding: '5px 10px',
                                    zIndex: 2,
                                    fontWeight: 500
                                }}
                            >
                                {formik.values?.list_domain && formik?.values?.list_domain.length === 0 ? (
                                    <button
                                        className="btn btn-sm btn-success edit-item-btn ms-2"
                                        type="button"
                                        // disabled={(isPreviewLoading !== null) || isLoading}
                                        onClick={(e) => { handleOnAddDomain(e) }}
                                        disabled={isLoading || isFilesLoading}
                                    >
                                        <i className="ri-add-circle-fill align-bottom"></i>
                                    </button>
                                ) : null}
                            </div>

                            <Col xxl={12}>
                                <label className="form-label">{t('Domain')}<span className="text-danger"> *</span></label>
                                <input
                                    className="form-control"
                                    id="domain"
                                    name="domain"
                                    placeholder={`${t('Enter Domain URL')}...`}
                                    onChange={(event: any) => formik.setFieldValue('domain', event?.target?.value || '')}
                                    value={formik.values?.domain}
                                />
                                {formik.touched.domain && formik.errors.domain ? (
                                    <div className="text-danger mt-2">
                                        {formik.errors.domain}
                                    </div>
                                ) : null}
                            </Col>
                         
                        </Row>

                        {formik.values?.list_domain.map((value, index ) => {
                            return (
                                (<Row key={index} style={{
                                    borderRadius: '5px',
                                    border: '1px dashed var(--vz-info)',
                                    backgroundColor: 'var(--vz-light)',
                                    margin: '8px 0px',
                                    padding: '8px 0px'
                                }} className="position-relative">
                                    <div className="position-absolute w-auto end-0 top-0"
                                        style={{
                                            transform: 'translateY(-50%)',
                                            padding: '5px 10px',
                                            zIndex: 2,
                                            fontWeight: 500
                                        }}
                                    >
                                        <button
                                            className="btn btn-sm btn-danger edit-item-btn ms-2"
                                            type="button"
                                            onClick={(e) => { handleOnRemoveDomain(e, value) }}
                                            disabled={isLoading || isFilesLoading}
                                        >
                                            <i className="ri-close-circle-fill align-bottom "></i>
                                        </button>
                                        {formik.values?.list_domain?.length === index + 1 && (<button
                                            className="btn btn-sm btn-success edit-item-btn ms-2"
                                            type="button"
                                            onClick={(e) => { handleOnAddDomain(e) }}
                                            disabled={isLoading || isFilesLoading}
                                        >
                                            <i className="ri-add-circle-fill align-bottom "></i>
                                        </button>)}
                                    </div>
                                    <Col xxl={12}>
                                        <label className="form-label">{t('Domain')} {index + 2}<span className="text-danger"> *</span></label>
                                        <input
                                            className="form-control"
                                            id={`list_domain${index}`}
                                            name={`list_domain${index}`}
                                            placeholder={`${t('Enter Domain URL')}...`}
                                            onChange={(event: any) => handleOnChangeListDomain(event, value)}
                                            value={(formik?.values?.list_domain?.[index] as any)?.domain}
                                        />
                                        {(formik.touched?.list_domain?.[index] as any)?.domain && (formik.errors?.list_domain?.[index] as any)?.domain ? (
                                            <div className="text-danger mt-2">
                                                {(formik?.errors?.list_domain?.[index] as any)?.domain}
                                            </div>
                                        ) : null}
                                    </Col>
                                </Row>)
                            )
                        })}
                    </Col>

                    <Col xxl={12}>
                        <label className="form-label">{t('Script URL')}<span className="text-danger"> *</span></label>
                        <input
                            className="form-control"
                            id="script_url"
                            name="script_url"
                            placeholder={`${t('Enter Script URL')}...`}
                            onChange={(event: any) => formik.setFieldValue('script_url', event?.target?.value || '')}
                            value={formik.values?.script_url}
                        />
                        {formik.touched.script_url && formik.errors.script_url ? (
                            <div className="text-danger mt-2">
                                {formik.errors.script_url}
                            </div>
                        ) : null}
                    </Col>

                    <Col xxl={12}>
                        <label className="form-label">{t('WEB_description')}</label>

                        <textarea className="form-control" rows={3}
                            onChange={(e: any) => formik.setFieldValue("description", e.target.value)}
                            placeholder={`${t('Enter Description')}...`}
                            value={formik.values?.description}
                        />
                    </Col>

                    {/* End::List Section */}
                    <div className="col-lg-12 mb-4">
                        <div className="hstack gap-2 justify-content-end">
                            <button className="btn btn-primary fs-14" color="success" type="submit" disabled={isLoading || isFilesLoading}>
                                {isLoading || isFilesLoading ? <Spinner size="sm" className="me-2"></Spinner> : (
                                    !!id ? <i className="ri-login-circle-line align-bottom me-1"></i> :
                                        <i className="ri-add-fill align-bottom me-1"></i>
                                )}
                                {id ? t('Button Update Media') : t('Button Create Media')}
                            </button>
                            <button className="btn btn-soft-secondary fs-14" color="light" type="button" onClick={close} disabled={isLoading}>
                                <i className="ri-indeterminate-circle-line align-bottom me-1"></i>
                                {t('Button Close')}
                            </button>
                        </div>
                    </div>
                </div>
            </form>
            <ToastContainer closeButton={false} limit={1} />
        </React.Fragment >
    );
};

export default MediaForm;
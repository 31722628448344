import { createSlice } from "@reduxjs/toolkit";
import { uploadFiles } from "./thunk";


export interface IState {
  files: any,
  isFilesLoading: boolean,
  isFilesSuccess: boolean,
  error: any,
};

export const initialState: IState = {
  files: '',
  isFilesLoading: false,
  isFilesSuccess: false,
  error: {},
};

const UploadSlice = createSlice({
  name: "Upload",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    //get files
    builder.addCase(uploadFiles.pending, (state: IState, action: any) => {
      state.isFilesLoading = true
    });
    builder.addCase(uploadFiles.fulfilled, (state: IState, action: any) => {
      state.files = action.payload.data;
      state.isFilesSuccess = true;
      state.isFilesLoading = false;
    });
    builder.addCase(uploadFiles.rejected, (state: IState, action: any) => {
      state.error = action.payload.error || null;
      state.isFilesSuccess = false;
      state.isFilesLoading = false;
    });
  },
});

export default UploadSlice.reducer;
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import AsyncSelect from 'react-select/async';
import { getCampaignsByNormal } from "../../store/thunks";
import { STATUS_CAMPAIGN } from "helpers/constans";
interface Option {
  label: string;
  value: string;
  isByEnter?: boolean
}
interface Filter {
  page?: number,
  limit?: number,
  name?: string;
}

interface SearchFilterCampaignProps {
  name?: string,
  initialValue?: Option[] | Option | undefined | null;
  disabled?: boolean,
  limitShow?: number,
  onChangeSelect?: (params: any) => void;
  isGetListOptionDefault?: boolean,
  isMulti?: boolean,
  isClearable?: boolean,
  placeholder?: string
}


const COLORS_STATUS_CAMPAIGN: any = {
  pending: "primary",
  running: "success",
  finished: "danger",
  waiting: "primary",
  error: "warning"
};

const SearchFilterCampaign = ({
  name = '',
  initialValue,
  isGetListOptionDefault = true,
  disabled = false,
  limitShow = 10,
  onChangeSelect,
  isMulti = false,
  isClearable = false,
  placeholder = '',
}: SearchFilterCampaignProps) => {
  const { t } = useTranslation();
  const [options, setOptions] = useState<Option[] | undefined>([]);

  const setOnChangeOption = (item: any, option: any = null) => {
    onChangeSelect && onChangeSelect(item)
  };
  const FILTER_BY = { page: 1, limit: limitShow, name: '' };

  const promiseOptions = (inputValue: string) => {
    return new Promise<Option[]>((resolve) => {
      getCampaignsByNormal({ ...FILTER_BY, name: inputValue }).then((response: any) => {
        const result = response?.data?.list?.map((item: any) => ({
          label: item?.name,
          value: item?.id,
          status: item?.status,
        })) || [];
        setOptions((_prev) => result);
        resolve(result);
      });
    });
  };

  const promiseCallCampaigns = async (params: Filter = {}) => {
    try {
      const response: any = await getCampaignsByNormal({ ...FILTER_BY, ...params });
      const result = response?.data?.list?.map((item: any) => ({
        label: item?.name,
        value: item?.id,
        status: item?.status,
      })) || [];
      setOptions((_prev) => result);
    } catch (error) {
      return error;
    }
  }


  useEffect(() => {
    if (isGetListOptionDefault) {
      promiseCallCampaigns();
    }
  }, [isGetListOptionDefault]);

  return (
    <React.Fragment>
      <AsyncSelect
        cacheOptions
        defaultOptions={options}
        isMulti={isMulti}
        isClearable={isClearable}
        isDisabled={disabled}
        closeMenuOnSelect={!isMulti}
        name={name}
        value={initialValue}
        loadOptions={promiseOptions}
        onChange={(e: any, op: any) => setOnChangeOption(e, op)}
        placeholder={<div>{placeholder || t('Select Campaign')}...</div>}
        loadingMessage={() => (<div>{t('Loading')}...</div>)}
        noOptionsMessage={() => (<div>{t('No Options')}</div>)}
        formatOptionLabel={(option: any) => (
          <div className="d-flex">{option?.label}
            <div className="op ms-2">
              <RenderStatus status={option?.status} />
            </div>
          </div>)}
        className="search-filter-campaign"
        classNamePrefix="name-prefix"
      />
    </React.Fragment>
  );
};

const RenderStatus = ({ status }: any) => {
  const { t } = useTranslation();
  const color = COLORS_STATUS_CAMPAIGN?.[`${status}`];

  return (<><div style={{ minWidth: '60px' }}><span className={`badge bg-${color}`}>{`${t(`Status_Campaign_${status}`)}`}</span></div></>);
}

export default SearchFilterCampaign;

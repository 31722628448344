import { combineReducers } from "redux";

// Front
import LayoutReducer from "./layouts/reducer";

// Authentication
import LoginReducer from "./auth/login/reducer";
import AccountReducer from "./auth/register/reducer";
import ForgetPasswordReducer from "./auth/forgetpwd/reducer";
import ProfileReducer from "./auth/profile/reducer";


// Dashboard CRM
import DashboardReducer from "./dashboard/reducer";

// User
import UserReducer from "./user/reducer";

// Role
import RoleReducer from "./role/reducer";

// Device
import DeviceReducer from "./device/reducer";


// Domain
import DomainReducer from "./domain/reducer";

//Campaign
import CampaignReducer from "./campaign/reducer";

// Notification
import NotificationReducer from "./notification/reducer";

//News
import NewsReducer from "./news/reducer";

//Upload
import UploadReducer from "./upload/reducer";

const rootReducer = combineReducers({
  Layout: LayoutReducer,
  Login: LoginReducer,
  Account: AccountReducer,
  ForgetPassword: ForgetPasswordReducer,
  Profile: ProfileReducer,
  Dashboard: DashboardReducer,
  User: UserReducer,
  Role: RoleReducer,
  Device: DeviceReducer,
  Domain: DomainReducer,
  Campaign: CampaignReducer,
  Notification: NotificationReducer,
  News: NewsReducer,
  Upload: UploadReducer,
});

export default rootReducer;
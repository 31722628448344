import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import {
    Container
} from "reactstrap";
import BreadCrumb from "components/Common/BreadCrumb";

import MediaForm from "./MediaForm";

const MediaCreate = () => {
    const { t, i18n } = useTranslation();

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <BreadCrumb
                        title={t("Create Media")}
                        pageTitle={t("Media")}
                    />
                    <MediaForm
                    />
                </Container>
            </div>
        </React.Fragment>
    );
};

export default MediaCreate;
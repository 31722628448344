import { AxiosResponse } from "axios";
import { APIClient } from "./axiosClient";
import { INotification } from "./types/_notification";
import { PaginationResponse, ResponseData } from "./types/_public";
const api = new APIClient();

const path = '/notifications';
const notificationApi = {
  notifications(params: any):Promise<AxiosResponse<ResponseData<INotification[]> & PaginationResponse, any>> {
    const url = `${path}`
    return api.get(url, params)
  },
  notification(id: string | number, params: any = {}) : Promise<AxiosResponse<ResponseData<INotification>, any>>  {
    const url = `${path}/${id}`;
    return api.get(url, params)
  },
  postNotification(dataForm: any) : Promise<AxiosResponse<ResponseData<INotification>, any>>  {
    const url = `${path}`;
    return api.post(url, dataForm)
  },
  putNotification(id: string | number, dataForm: any) : Promise<AxiosResponse<ResponseData<INotification>, any>>  {
    const url = `${path}/${id}`;
    return api.put(url, dataForm)
  },
  deleteNotification(id: string | number, dataForm: any) : Promise<AxiosResponse<ResponseData<INotification>, any>>  {
    const url = `${path}/${id}`;
    return api.delete(url, dataForm)
  },
  transmissionList(params: any):Promise<AxiosResponse<ResponseData<any[]> & PaginationResponse, any>> {
    const url = `${path}/get-notification-campaign`
    return api.get(url, params)
  },
  notificationClickList(params: any):Promise<AxiosResponse<ResponseData<any[]> & PaginationResponse, any>> {
    const url = `${path}/get-notification-click`
    return api.get(url, params)
  },

  getNotificationsListAllOS() : Promise<AxiosResponse<ResponseData<any>, any>>  {
    const url = `${path}/os/all`;
    return api.get(url)
  },

  getNotificationsListAllDevice() : Promise<AxiosResponse<ResponseData<any>, any>>  {
    const url = `${path}/device/all`;
    return api.get(url)
  },
}
export default notificationApi

import { createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import roleApi from 'api/roleApi';

//Include Both Helper File with needed methods
import UploadApi from "api/uploadApi";
import { formatQueryParams } from "helpers/format";

export const uploadFiles = createAsyncThunk(
  "Upload",
  async (params: any = {}) => {
    try {
      const response: any = await UploadApi.uploadFile(params);
      return response;
    } catch (error) {
      return error;
    }
  }
);


import { AxiosResponse } from "axios";
import { APIClient } from "./axiosClient"
import { PaginationResponse, ResponseData } from "./types/_public";
import { INews, IPreviewNews, IScriptTrackingNews, IUploadImage } from "./types/_news";
const api = new APIClient();

const path = '/news';
const newsApi = {
  news(params: any):Promise<AxiosResponse<ResponseData<INews[]> & PaginationResponse, any>> {
    const url = `${path}`
    return api.get(url, params)
  },
  detailNews(id: string | number, params: any = {}):Promise<AxiosResponse<ResponseData<INews>, any>> {
    const url = `${path}/${id}`
    return api.get(url, params)
  },
  preview(dataForm: any) : Promise<AxiosResponse<ResponseData<IPreviewNews>, any>>  {
    const url = `${path}/preview`;
    return api.post(url, dataForm, {
      timeout: 1000 * 60 * 10,
    })
  },
  postNews(dataForm: any) : Promise<AxiosResponse<ResponseData<INews>, any>>  {
    const url = `${path}`;
    return api.post(url, dataForm, {
      timeout: 1000 * 60 * 10,
    })
  },
  putNews(id: string | number, dataForm: any) : Promise<AxiosResponse<ResponseData<INews>, any>>  {
    const url = `${path}/${id}`;
    return api.put(url, dataForm)
  },
  putReCreateNews(id: string | number, dataForm: any = {}) : Promise<AxiosResponse<ResponseData<INews>, any>>  {
    const url = `${path}/${id}/re-create`;
    return api.put(url, dataForm)
  },
  putMultiReCreateNews(dataForm: any = {}) : Promise<AxiosResponse<ResponseData<INews>, any>>  {
    const url = `${path}/re-create`;
    return api.put(url, dataForm)
  },
  putResetStatusNews(id: string | number, dataForm: any = {}) : Promise<AxiosResponse<ResponseData<INews>, any>>  {
    const url = `${path}/${id}/status/${dataForm?.status}`;
    return api.put(url, dataForm)
  },
  putMultiResetStatusNews(dataForm: any = {}) : Promise<AxiosResponse<ResponseData<INews>, any>>  {
    const url = `${path}/status`;
    return api.put(url, dataForm)
  },
  putConfirmNews(id: string | number, dataForm: any = {}) : Promise<AxiosResponse<ResponseData<INews>, any>>  {
    const url = `${path}/${id}/confirm`;
    return api.put(url, dataForm)
  },
  deleteNews(id: string | number, dataForm: any) : Promise<AxiosResponse<ResponseData<INews>, any>>  {
    const url = `${path}/${id}`;
    return api.delete(url, dataForm)
  },
  downloadFile(id: string | number, params: any = {}):Promise<AxiosResponse<ResponseData<INews>, any>> {
    const url = `${path}/${id}/download`
    return api.get(url, params)
  },
  downloadImageNews(id: string | number, params: any = {}):Promise<AxiosResponse<ResponseData<INews>, any>> {
    const url = `${path}/${id}/download-image`
    return api.get(url, params)
  },
  renderScriptTrackingNews(id: string | number, params: any = {}):Promise<AxiosResponse<ResponseData<IScriptTrackingNews>, any>> {
    const url = `${path}/${id}/script-tracking`
    return api.get(url, params)
  },
  uploadImage(dataForm: any) : Promise<AxiosResponse<IUploadImage[], any>>  {
    const url = `upload/r2-files`;
    // const formData = new FormData();
    // formData.append('file', file);
    return api.post(url, dataForm, {
      timeout: 1000 * 60 * 10,
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
  },
}
export default newsApi;
import { Option } from "api/types/_public";

export const optionsType: any = [
  { value: "gt:1-eq:1000", label: "1~1000" },
  { value: "gt:1000-eq:5000", label: "1000~5000" },
  { value: "gt:5000-eq:10000", label: "5000~10000" },
  { value: ">10000", label: "> 10000" },
];

export const optionsTypeClick: any = [
  { value: "gt:1-eq:100", label: "1~100" },
  { value: "gt:100-eq:500", label: "100~500" },
  { value: "gt:500-eq:1000", label: "500~1000" },
  { value: ">1000", label: "> 1000" },
];

export const optionsTypeRate: any = [
  { value: "gt:1-eq:10", label: "1~10" },
  { value: "gt:10-eq:50", label: "50~100" },
  { value: "gt:50-eq:100", label: "50~100" },
  { value: ">100", label: "> 100" },
];

export const typeSearch: any = [
  { value: "search_monthly", label: "Search Monthly" },
  { value: "search_monthly_pc", label: "Search Monthly PC" },
  { value: "search_monthly_mobile", label: "Search Monthly Mobile" },
  { value: "click_avg_monthly", label: "Click Avg Monthly" },
  { value: "click_avg_monthly_pc", label: "Click Avg Monthly PC" },
  { value: "click_avg_monthly_mobile", label: "Click Avg Monthly Mobile" },
  // { value: 'click_rate_monthly', label: 'Click Rate Monthly' },
  // { value: 'click_rate_monthly_pc', label: 'Click Rate Monthly PC' },
  // { value: 'click_rate_monthly_mobile', label: 'Click Rate Monthly Mobile' },
  // { value: 'impressions_avg_monthly', label: 'Impressions Avg Monthly' },
];

export const typeSearchCar: any = [
  { value: "total_sales", label: "Total sales" },
  { value: "used", label: "Local" },
  { value: "export", label: "Export" },
  { value: "manufacture", label: "Manufacture" },
];

export const typeSearchMovie: any = [
  { value: "audience", label: "Audience" },
  { value: "sales", label: "Sales" },
  { value: "screen", label: "Screen" },
];

export const typeSearchLuxury: any = [
  { value: "naver_search_monthly", label: "Naver Search" },
];

export const categoryTemplateNews: any = [{ value: "rank", label: "Rank" }];

export const typeSearchCelebrity: any = [
  { value: "naver_search_monthly", label: "Naver Search" },
];

export const typeSearchTrendIndex: any = [
  { value: "index_trend", label: "Index Trend" },
];

export const SCHEDULE_OPTIONS: Option[] = [
  {
    label: "Now",
    value: "now",
  },
  {
    label: "Daily",
    value: "daily",
  },
  {
    label: "Weekly",
    value: "weekly",
  },
  {
    label: "Monthly",
    value: "monthly",
  },
  // {
  //   label: "Custom",
  //   value: "custom",
  // },
  // {
  //   label: 'Except Holiday In Korea',
  //   value: 'except_holiday_in_Korea',
  // }
];

export const SCHEDULE_MONTHLY_OPTIONS: Option[] = Array.from(
  { length: 31 },
  (_, index) => {
    const day = (index + 1).toString().padStart(2, "0");
    return { label: `${day}th`, value: day };
  }
);

export const SCHEDULE_WEEKLY_OPTIONS: Option[] = [
  {
    label: "Monday",
    value: "2",
  },
  {
    label: "Tuesday",
    value: "3",
  },
  {
    label: "Wednesday",
    value: "4",
  },
  {
    label: "Thursday",
    value: "5",
  },
  {
    label: "Friday",
    value: "6",
  },
  {
    label: "Saturday",
    value: "7",
  },
  {
    label: "Sunday",
    value: "1",
  },
];

export const TYPE_TRANSMISSION_FORMAT__MEDIA_OPTIONS: Option[] = [
  {
    label: "Post",
    value: "post",
  },
  {
    label: "Api",
    value: "api",
  },
];

export const CATEGORY_TEMPLATE_OPTIONS: Option[] = [
  {
    label: "Rank",
    value: "rank",
  },
];

export const STATUS_CAMPAIGN_OPTIONS: Option[] = [
  {
    label: "Status_Campaign_1", // Waiting
    value: "1",
  },
  {
    label: "Status_Campaign_2", // Running
    value: "2",
  },
  {
    label: "Status_Campaign_3", // Finish
    value: "3",
  },
];

export const STATUS_CAMPAIGN = {
  WAITING: 1,
  RUNNING: 2,
  FINISH: 3,
};

export const ACTION_TYPE_CAMPAIGN = {
  SAVE: "save",
  POST: "post",
};


export const PLATFORM_LOG_OPTIONS: Option[] = [
  {
    label: "Platform_naver",
    value: "naver",
  },
  {
    label: "Platform_google",
    value: "google",
  },
  // {
  //   label: "Platform_daum",
  //   value: "daum",
  // },
  {
    label: "Platform_etc",
    value: "etc",
  },
  
];

export const DEVICE_OPTIONS: Option[] = [
];

export const BOT_OPTIONS: Option[] = [
  {
    label: "Bot_0",
    value: '0',
  },
  {
    label: "Bot_1",
    value: '1',
  },
];

export const STATUS_KEYWORD_OPTIONS: Option[] = [
  {
    label: "Status_Keyword_0", // Initial
    value: "0",
  },
  {
    label: "Status_Keyword_2", // Analyzing
    value: "2",
  },
  {
    label: "Status_Keyword_1", // Analyzed
    value: "1",
  },
];


export const STATUS_NEWS_OPTIONS: Option[] = [
  {
    label: "Status_News_0", // Initial
    value: "0",
  },
  {
    label: "Status_News_1", // Analyzed
    value: "1",
  },
  {
    label: "Status_News_5", // Warning
    value: "5",
  },
  {
    label: "Status_News_2", // Confirmed
    value: "2",
  },
  {
    label: "Status_News_3", // Sent
    value: "3",
  },
  {
    label: "Status_News_4", // Published
    value: "4",
  },

];


export const TYPE_NEWS_OPTIONS: Option[] = [
  {
    label: "Type_News_1", // Trend News
    value: "1",
  },
  {
    label: "Type_News_0", // Media News
    value: "0",
  },
];

export const TYPE_KEYWORD_OPTIONS: Option[] = [
  {
    label: "Type_Keyword_0", // Trend Keyword
    value: "0",
  },
  {
    label: "Type_Keyword_1", // Media Keyword
    value: "1",
  },
];

export const BROWSER_OPTIONS: Option[] = [
  {
    label: "Browser_Chrome",
    value: 'Chrome',
  },
  {
    label: "Browser_Safari",
    value: 'Safari',
  },
  {
    label: "Browser_Unknown_Browser",
    value: 'Unknown Browser',
  },
];


export const OS_OPTIONS: Option[] = [];


export const TYPE_STOCK_OPTIONS: Option[] = [
  {
    label: "Type_Stock_1", // kospi
    value: "1",
  },
  {
    label: "Type_Stock_2", // kosdaq
    value: "2",
  },
];

export const IS_OPTIONS: Option[] = [
  {
    label: "YES",
    value: "1",
  },
  {
    label: "NO",
    value: "0",
  },
];


export const STATUS_CHANGE_STOCK_OPTIONS: Option[] = [
  {
    label: "Status_Change_Stock_1", // Upper limit
    value: "1",
  },
  {
    label: "Status_Change_Stock_2", // Increase
    value: "2",
  },
  {
    label: "Status_Change_Stock_3", // No change
    value: "3",
  },
  {
    label: "Status_Change_Stock_4", // Lower limit
    value: "4",
  },
  {
    label: "Status_Change_Stock_5", // Decrease
    value: "5",
  },
];
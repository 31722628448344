import { AxiosResponse } from "axios";
import { APIClient } from "./axiosClient";
import { IDevice } from "./types/_device";
import { PaginationResponse, ResponseData } from "./types/_public";
const api = new APIClient();

const path = '/subscriptions';
const DeviceApi = {
  devices(params: any):Promise<AxiosResponse<ResponseData<IDevice[]> & PaginationResponse, any>> {
    const url = `${path}`
    return api.get(url, params)
  },
  getDevice(id: string | number, params: any = {}) : Promise<AxiosResponse<ResponseData<IDevice>, any>>  {
    const url = `${path}/${id}`;
    return api.get(url, params)
  },
  postDevice(dataForm: any) : Promise<AxiosResponse<ResponseData<IDevice>, any>>  {
    const url = `${path}`;
    return api.post(url, dataForm)
  },
  putDevice(id: string | number, dataForm: any) : Promise<AxiosResponse<ResponseData<IDevice>, any>>  {
    const url = `${path}/${id}`;
    return api.put(url, dataForm)
  },
  deleteDevice(id: string | number, dataForm: any) : Promise<AxiosResponse<ResponseData<IDevice>, any>>  {
    const url = `${path}/${id}`;
    return api.delete(url, dataForm)
  },
  getSubscriptionsALlOS() : Promise<AxiosResponse<ResponseData<any>, any>>  {
    const url = `${path}/os/all`;
    return api.get(url)
  },

  getSubscriptionsAllDevice() : Promise<AxiosResponse<ResponseData<any>, any>>  {
    const url = `${path}/device/all`;
    return api.get(url)
  },
}
export default DeviceApi

import { IDevice } from 'api/types/_device';
import { IRole } from 'api/types/_role';
import { CONFIG_OPTION_TOAST_ERROR, CONFIG_OPTION_TOAST_NORMAL } from 'common/toast';
import TooltipCustom from 'components/Common/TooltipCustom';
import { useRole } from 'components/Hooks/UserHooks';
import en from 'date-fns/locale/en-US';
import ko from 'date-fns/locale/ko';
import { ROLES_FOR_APP, isHavePermissionRole } from 'helpers/role';
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Card, CardBody, CardHeader, Col, Container, Input, Modal, ModalBody, ModalHeader, Row, Spinner } from "reactstrap";
import { createSelector } from "reselect";
import { NumberParam, StringParam, useQueryParams, withDefault, } from "use-query-params";
import BreadCrumb from "../../../components/Common/BreadCrumb";
import TableContainer from "../../../components/Common/TableContainer";
import { getAllRoles, getDevices as onGetDevices, putDevice, getSubscriptionsALlOS, getSubscriptionsAllDevice } from "../../../store/thunks";
import DropdownOption from 'components/Common/DropdownOption';
import CountUp from 'react-countup';
import moment from 'moment';
import { BOT_OPTIONS, BROWSER_OPTIONS, DEVICE_OPTIONS, OS_OPTIONS, PLATFORM_LOG_OPTIONS, TYPE_NEWS_OPTIONS } from 'helpers/constans';
import DatePickerCustom from 'components/Common/DatePickerCustom';
import { getAllDomains } from 'store/domain/thunk';
import { IDomain } from 'api/types/_domain';
import SearchFilterDomain from 'components/Common/SearchFilterDomain';
import FeatherIcon from "feather-icons-react";

interface Option {
  label: string;
  value: string;
}

export interface Tag {
  id: string;
  text: string;
}

const TYPE_SELECT_DEFAULT: string = '';

registerLocale('en', en);
registerLocale('ko', ko);


const COLORS_STATUS: any = {
  subscribed: "success",
  unsubscribed: "danger",
};

const DeviceList = () => {
  const { t, i18n } = useTranslation();
  const { userPermissions } = useRole();
  const navigate = useNavigate();

  const PLATFORM_LOG_OPTIONS_LANG = PLATFORM_LOG_OPTIONS?.map((item: any) => ({ value: item?.value, label: t(item?.label) })) || [];

  const DEVICE_OPTIONS_LANG = DEVICE_OPTIONS?.map((item: any) => ({ value: item?.value, label: t(item?.label) })) || [];

  const BOT_OPTIONS_LANG = BOT_OPTIONS?.map((item: any) => ({ value: item?.value, label: t(item?.label) })) || [];

  const BROWSER_OPTIONS_LANG = BROWSER_OPTIONS?.map((item: any) => ({ value: item?.value, label: t(item?.label) })) || [];

  const OS_OPTIONS_LANG = OS_OPTIONS?.map((item: any) => ({ value: item?.value, label: t(item?.label) })) || [];

  const TYPE_NEWS_OPTIONS_LANG = TYPE_NEWS_OPTIONS?.map((item: any) => ({ value: item?.value, label: t(item?.label) })) || [];

  const [query, setQuery]: any = useQueryParams({
    time_request: withDefault(NumberParam, 0),
    page: withDefault(NumberParam, 1),
    limit: withDefault(NumberParam, 30),
    sort_by: withDefault(StringParam, TYPE_SELECT_DEFAULT),
    order_by: withDefault(StringParam, ``),
    media_id: withDefault(StringParam, ''),
    title: withDefault(StringParam, ''),
    reference_link: withDefault(StringParam, ''),
    ip: withDefault(StringParam, ''),
    og_url: withDefault(StringParam, ''),
    keyword: withDefault(StringParam, ''),
    start_date: withDefault(StringParam, ''),
    end_date: withDefault(StringParam, ''),
    platform: withDefault(StringParam, ''),
    device: withDefault(StringParam, ''),
    browser: withDefault(StringParam, ''),
    bot: withDefault(StringParam, ''),
    is_newshub: withDefault(StringParam, ''),
    uuid: withDefault(StringParam, ''),
    hostname: withDefault(StringParam, ''),
  });

  const [dateSearch, setDateSearch] = useState<any[]>([query?.start_date ? moment(query?.start_date || "", 'Y-MM-DD').toDate() : '', query?.end_date ? moment(query?.end_date || "", 'Y-MM-DD').toDate() : '']);
  const [startDate, endDate] = dateSearch;

  const [referenceLinkSearch, setReferenceLinkSearch] = useState<string>(query?.reference_link || "");
  const [ipSearch, setIpSearch] = useState<string>(query?.ip || "");

  const [urlSearch, setUrlSearch] = useState<string>(query?.og_url || "");

  const [keywordSearch, setKeywordSearch] = useState<string>(query?.keyword || "");

  const [titleSearch, setTitleSearch] = useState<string>(query?.title || "");

  const [listDomain, setListDomain] = useState<IDomain[]>([]);

  const [domainSearch, setDomainSearch] = useState<Option | null>({
    label: listDomain?.filter((item) => String(item?.website || '') === String(query?.domain || ''))[0]?.name || t('All Media'),
    value: `${listDomain?.filter((item) => String(item?.website || '') === String(query?.domain || ''))[0]?.id || ''}`
  });

  const [platformSearch, setPlatformSearch] = useState<Option | null>(PLATFORM_LOG_OPTIONS_LANG?.filter((item: any) => String(item?.value) === String(query?.platform))[0]);

  const [deviceSearch, setDeviceSearch] = useState<Option | null>(DEVICE_OPTIONS_LANG?.filter((item: any) => String(item?.value) === String(query?.device))[0]);

  const [botSearch, setBotSearch] = useState<Option | null>(BOT_OPTIONS_LANG?.filter((item: any) => String(item?.value) === String(query?.bot))[0]);

  const [browserSearch, setBrowserSearch] = useState<Option | null>(BROWSER_OPTIONS_LANG?.filter((item: any) => String(item?.value) === String(query?.browser))[0]);

  const [osSearch, setOsSearch] = useState<Option | null>(OS_OPTIONS_LANG?.filter((item: any) => String(item?.value) === String(query?.os))[0]);

  const [typeNewsSearch, setTypeNewsSearch] = useState<Option | null>(TYPE_NEWS_OPTIONS_LANG?.filter((item: any) => String(item?.value) === String(query?.is_newshub))[0]);

  const [previewDetail, setPreviewDetail] = useState<any | null>(null);

  const [userUUIDSearch, setUserUUIDSearch] = useState<string>(query?.userUUID || "");


  const [listDevice, setListDevice] = useState<any>(DEVICE_OPTIONS_LANG);

  const [listOS, setListOS] = useState<any>(OS_OPTIONS_LANG);

  const [listHostName, setListHostName] = useState<any>([]);

  const [hostNameSearch, setHostNameSearch] = useState<Option | null>(
  //   {
  //   label: listDomain?.filter((item) => String(item?.website || '') === String(query?.domain || ''))[0]?.name || t('All Media'),
  //   value: `${listDomain?.filter((item) => String(item?.website || '') === String(query?.domain || ''))[0]?.id || ''}`
  // }
  );

  // Inside your component

  const dispatch: any = useDispatch();

  const selectLayoutState = (state: any) => state.Device;

  const DeviceProperties = createSelector(
    selectLayoutState,
    (state) => ({
      devices: state.devices,
      isDeviceSuccess: state.isDeviceSuccess,
      isDeviceLoading: state.isDeviceLoading,
      error: state.error,
    })
  );

  // Inside your component
  const { devices, error, isDeviceLoading } = useSelector(DeviceProperties);


  useEffect(() => {
    dispatch(onGetDevices(query));
  }, [dispatch, JSON.stringify(query)]);


  const searchData = () => {
    const queryNew = {
      ...query,
      title: titleSearch || "",
      reference_link: referenceLinkSearch || "",
      ip: ipSearch || "",
      og_url: urlSearch || '',
      keyword: keywordSearch || "",
      media_id: domainSearch?.value ? listDomain?.filter((item: any) => Number(item?.id) === Number(domainSearch?.value))[0]?.website || domainSearch?.value : '',
      device: deviceSearch?.value || '',
      bot: botSearch?.value ?? '',
      browser: browserSearch?.value ?? '',
      platform: osSearch?.value ?? '',
      is_newshub: typeNewsSearch?.value ?? '',
      start_date: startDate ? moment(new Date(startDate)).format("Y-MM-DD") : '',
      end_date: endDate ? moment(new Date(endDate)).format("Y-MM-DD") : '',
      sort_by: TYPE_SELECT_DEFAULT,
      page: 1,
      time_request: + new Date(),
      uuid: userUUIDSearch || "",
      hostname: hostNameSearch?.value ?? ''
    };

    setQuery(queryNew);
  };

  const resetData = () => {
    const queryNew = {
      ...query,
      title: '',
      reference_link: '',
      ip: '',
      og_url: '',
      keyword: '',
      media_id: '',
      sort_by: TYPE_SELECT_DEFAULT,
      start_date: undefined,
      end_date: undefined,
      platform: '',
      device: '',
      bot: '',
      browser: '',
      is_newshub: '',
      page: 1,
      time_request: + new Date(),
      uuid: '',
      hostname: ''
    };
    setQuery(queryNew, "push");
    setReferenceLinkSearch((_prev) => "");
    setIpSearch((_prev) => "");
    setUrlSearch((_prev) => "");
    setKeywordSearch((_prev) => "");
    setTitleSearch((_prev) => "");
    setDomainSearch({ label: t('All Media'), value: '' });
    setDateSearch(["", ""]);
    setPlatformSearch(null);
    setDeviceSearch(null);
    setBotSearch(null);
    setBrowserSearch(null);
    setOsSearch(null);
    setTypeNewsSearch(null);
    setUserUUIDSearch((_prev) => "")
    setHostNameSearch({ label: t('All Domain'), value: '' });
  };


  // Begin::Update Device
  const onCloseClick = () => {

  }

  const triggerRefresh = () => {
    onCloseClick();
    dispatch(onGetDevices(query));
  }
  //End::Update Device

  function onClosePreviewDetailClick() {
    setPreviewDetail((_prev: any) => null);
  }

  const handlePreviewDetail = async (item: any | null = null) => {
    try {
      setPreviewDetail((_prev: any) => item);
    } catch (error: any) {
      return error;
    }
  };

  const formatDate = (strDate: string = '') => {
    return `${String(strDate).split('T')[0]} ${String(String(strDate).split('T')[1]).split('.')[0]}`;
  }

  const showListDomain = (domain: any, hostname: any) => {
    if (domain) {
      const valueConvertArr = domain.split(',')
      const show: any[] = []
      valueConvertArr.map((value: any) => {
        show.push(<div className='d-flex flex-row align-items-center' style={{ gap: '8px' }}>
          <Link className='text-normal' to={String(value).includes('https://') ? value : `https://${value}`} target="_blank">
            {value}
          </Link>
          {hostname === value && (<div className='remove-list-style'>
            <TooltipCustom
              title={t('User operated with this domain')}
              className="d-inline-block vertical-align-middle ms-1 remove-list-style"
              style={{ transform: 'translateY(2px)' }}
            >
              <div id={`check-domain`}>
                <FeatherIcon class="text-success" icon="check-circle" size={16} />
              </div>
            </TooltipCustom>

          </div>
          )}
        </div>
        )
      })

      return <div className='d-flex' style={{ flexDirection: 'column', gap: '2px' }}>{show}</div>
    } else {
      return null;
    }
  }

  // Column
  const columns = useMemo(
    () => [
      {
        Header: t('WEB_userUUID'),
        accessor: "uuid",
        filterable: true,
        sortable: true,
        Cell: (cell: any) => {
          const item = cell?.row?.original;
          return (<>
            <div>{cell?.value}</div>
          </>)
        },
      },
      {
        Header: t('WEB_device'),
        accessor: "device_name",
        filterable: true,
        sortable: true,
        Cell: (cell: any) => {
          return (<>
            <div>{t(cell?.value)}</div>
          </>)
        },
      },
      {
        Header: t('WEB_platform'),
        accessor: "platform",
        filterable: true,
        sortable: true,
        Cell: (cell: any) => {
          return (<>
            <div>{cell?.value}</div>
          </>)
        },
      },
      {
        Header: t('Browser'),
        accessor: "browser",
        filterable: true,
        sortable: true,
        Cell: (cell: any) => {
          return (<>
            <div>{cell?.value}</div>
          </>)
        },
      },
      {
        Header: t('Media'),
        accessor: "domain",
        filterable: true,
        sortable: false,
        Cell: (cell: any) => {
          const item = cell?.row?.original;
          const { media: { domain, name }, hostname } = item
          return (<>
            <div className='d-flex flex-column' style={{ minWidth: 'max-content' }}>
              <div>{name}</div>
              <div className="cursor-pointer">
                {showListDomain(domain, hostname)}
                {/* {domain && (<>
                  <Link className='text-normal' to={String(domain).includes('https://') ? domain : `https://${domain}`} target="_blank">
                    {domain}
                  </Link>
                </>
                )} */}
              </div>
            </div>
          </>)
        },
      },
      {
        Header: t('Status'),
        accessor: "status",
        filterable: true,
        sortable: true,
        Cell: (cell: any) => {
          const color = COLORS_STATUS[cell?.value];
          return <span className={`badge bg-${color}`}>{t(cell?.value)}</span>

        },
      },
      {
        Header: t('WEB_created_at'),
        accessor: "created_at",
        filterable: true,
        sortable: true,
        Cell: (cell: any) => {
          const arrDate = formatDate(cell?.value).split(' ');
          return (
            <>
              <span><span>{arrDate?.[0] || ''}</span> <br /> <span className="text-secondary">{arrDate?.[1] || ''}</span> </span>
            </>
          )
        },
      },
      {
        Header: t('Button Action'),
        thClass: 'justify-content-center',
        thWidth: 60,
        Cell: (cell: any) => {
          const item: any = cell?.row?.original;
          return (
            <ul className="list-inline flex-wrap justify-content-center hstack gap-2 mb-0">
              <TooltipCustom
                title={t('Detail')}
                id={`detail-log-${item?.id}`}
              >
                <li className=" list-inline-item me-0" id={`detail-log-${item?.id}`} >
                  <Link className="btn btn-sm btn-soft-secondary edit-item-btn" to="#"
                    onClick={(e) => { e.preventDefault(); handlePreviewDetail(item) }}
                  >
                    <i className="ri-eye-fill align-bottom"></i>
                  </Link>
                </li>
              </TooltipCustom>
            </ul>
          );
        },
      },
    ],
    [i18n?.language, query, userPermissions]
  );


  const handleChangePicker = (values: any[] = []) => {
    setDateSearch((_prev: any) => values);
  }


  const handleChangePage = useCallback((page: any) => {
    setQuery({ page: page + 1 })
  }, []);

  const handleChangeSorting = useCallback((sortBy: any) => {
    // if (sortBy?.sort_by === 'domain') {
    //   sortBy.sort_by = 'domainName'
    // }
    setQuery((_prev: any) => {
      return ({ ..._prev, ...sortBy });
    })
  }, []);

  const handleSetListOS = (resListAllOS: any) => {
    if (resListAllOS && resListAllOS?.data && resListAllOS?.data?.list) {
      const listALLOS = resListAllOS?.data?.list.map((item: string) => ({ label: item, value: item }));
      return listALLOS
    } else {
      return []
    }
  }

  const handleSetListDevice = (resListDevice: any) => {
    if (resListDevice && resListDevice?.data && resListDevice?.data?.list) {
      const listAllDevice = resListDevice?.data?.list.map((item: string) => ({ label: t(item), value: item }));
      return listAllDevice
    } else {
      return []
    }
  }

  const handleCallAllOption = async () => {
    try {
      const [resDomain, resListAllOS, resListAllDevice]: any = await Promise.all([getAllDomains(), getSubscriptionsALlOS(), getSubscriptionsAllDevice()]);
      if (resDomain) {
        const res = resDomain?.data?.list?.map((item: any, index: number) => ({
          id: item?.id,
          name: item?.name,
          website: item?.domain,
        }));
        const list = ([{ name: t('All Media'), id: '', website: '' } as any]).concat(res || []);
        setListDomain((_prev) => list);

        setListOS((_prev: any) => handleSetListOS(resListAllOS))

        setListDevice((_prev: any) => handleSetListDevice(resListAllDevice))


      }
    } catch (error: any) {
      return error;
    }
  };

  const handleChangeStatusItem = async (item: any, status: boolean) => {
    try {
      const { email, fist_name, last_name, role_id } = structuredClone(item);
      const data = {
        email,
        fist_name,
        last_name,
        role_id,
        is_active: Number(status),
      };
      const response: any = await putDevice(item?.id, data);
      if (response?.data) {
        dispatch(onGetDevices(query));
        toast(`${t('The process has been completed.')}`, CONFIG_OPTION_TOAST_NORMAL);
      } else {
        toast(`${response}`, CONFIG_OPTION_TOAST_ERROR);
      }
    } catch (error: any) {
      toast(`${error?.message || ''}`, CONFIG_OPTION_TOAST_ERROR);
      return error;
    }
  }


  useEffect(() => {
    handleCallAllOption();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  useEffect(() => {
    if (platformSearch) {
      setPlatformSearch((_prev: any) =>
        PLATFORM_LOG_OPTIONS_LANG?.filter((e: any) => e.value === platformSearch?.value)[0]
      );
    }

    if (deviceSearch) {
      setDeviceSearch((_prev: any) =>
        DEVICE_OPTIONS_LANG?.filter((e: any) => e.value === deviceSearch?.value)[0]
      );
    }

    if (botSearch) {
      setBotSearch((_prev: any) =>
        BOT_OPTIONS_LANG?.filter((e: any) => e.value === botSearch?.value)[0]
      );
    }

    if (domainSearch) {
      setListDomain((_prev) => (_prev || [])?.map((item) => item?.id ? item : ({ ...item, name: t('All Media') })));
      (!domainSearch?.value) && setDomainSearch((prev) => ({ label: t('All Media'), value: '' }));
    }

    if (browserSearch) {
      setBrowserSearch((_prev: any) =>
        BROWSER_OPTIONS_LANG?.filter((e: any) => e.value === browserSearch?.value)[0]
      );
    }

    if (osSearch) {
      setOsSearch((_prev: any) =>
        OS_OPTIONS_LANG?.filter((e: any) => e.value === osSearch?.value)[0]
      );
    }

    if (typeNewsSearch) {
      setTypeNewsSearch((_prev: any) =>
        TYPE_NEWS_OPTIONS_LANG?.filter((e: any) => e.value === typeNewsSearch?.value)[0])
    }

    if (listDevice) {
      setListDevice(DEVICE_OPTIONS_LANG)
    }

    if (hostNameSearch) {
      setListHostName((_prev: any) => (_prev || [])?.map((item: { id: any; }) => item?.id ? item : ({ ...item, name: t('All Domain') })));
      (!hostNameSearch?.value) && setHostNameSearch((prev) => ({ label: t('All Domain'), value: '' }));
    }


    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [i18n?.language]);

  useEffect(() => {
    document.title = `${t('Media')} - ${t('Subscription')} | MessageHub`;
    document.body.classList.remove('vertical-sidebar-enable');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [i18n?.language]);

  useEffect(() => {
    const handleUpdateDomain = () => {
      if (domainSearch && domainSearch.value) {
        const getDomain = listDomain.find((item: any) => {
          return item.id === domainSearch.value
        })
        if (getDomain && getDomain.website) {
          const domainArr = getDomain.website.split(',')
          const listDomainOptions = domainArr.map(item => ({ label: item, value: item }));
          setListHostName(listDomainOptions)
        }
      } else {
        setListHostName([])
      }
    }

    handleUpdateDomain()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [domainSearch])

  const handleOnChangeDomainSearch = (event: any) => {
    setDomainSearch((_prev) => event);
  }

  const handleOnDisableListDomain = () => {
    if (domainSearch && !domainSearch.value) {
      return true
    }
    return false;
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title={t('Subscription')} pageTitle={t('Media')} />
          <Row>
            {isHavePermissionRole(ROLES_FOR_APP.DEVICE_LIST, userPermissions) && (
              <Col lg={12}>
                <Card id="leadsList">
                  <CardHeader className="border-0">
                    <div className="d-flex flex-column flex-md-row g-4 align-items-start align-items-md-start mb-2 mt-2">
                      <Card className="card-animate mb-0 me-0 me-md-4 mt-3 bg-primary-subtle text-primary border-0" style={{ width: '200px' }}>
                        <CardBody>
                          <div className="d-flex align-items-center ">
                            <div className="flex-grow-1 overflow-hidden">
                              <p className="text-uppercase fw-medium text-primary text-truncate mb-0">{t('Total')}</p>
                            </div>
                          </div>
                          <div className="d-flex align-items-end justify-content-between mt-2 pt-1">
                            <div>
                              <h4 className="fs-22 fw-semibold ff-secondary mb-0">
                                <span className="counter-value text-primary">
                                  {isDeviceLoading ? <Spinner size="sm" ></Spinner> : (
                                    <CountUp
                                      start={0}
                                      end={devices?.pagination?.total || 0}
                                      duration={1}
                                    />
                                  )}
                                </span></h4>
                            </div>
                          </div>
                        </CardBody>
                      </Card>
                      <div className="w-100">
                        <Row className="g-4 align-items-center mb-2 mb-md-0 mt-0">
                          <Col sm={12} md={12} lg={6} xl={3} className='mt-3'
                          >
                            <div>
                              <SearchFilterDomain
                                name="domains"
                                isMulti={false}
                                dataList={listDomain}
                                initialValue={domainSearch}
                                onChangeSelect={(event: any) => {
                                  handleOnChangeDomainSearch(event)
                                }}
                              />
                            </div>
                          </Col>

                          <Col sm={12} md={12} lg={6} xl={3} className='mt-3'
                          >
                            <DropdownOption
                              name="format"
                              dataList={listHostName || []}
                              placeholder={`${t("Domain")}...`}
                              className="search-filter-category-type"
                              classNamePrefix="name-prefix"
                              initialValue={hostNameSearch || null}
                              onChangeSelect={(e: any) => setHostNameSearch(e)}
                              isHasOptionAll={true}
                              optionAll={{ label: t('All Domain'), value: '' }}
                              disabled={handleOnDisableListDomain()}
                            />
                          </Col>

                          <Col sm={12} md={12} lg={6} xl={3} className='mt-3'
                          >
                            <DropdownOption
                              name="format"
                              dataList={listDevice || []}
                              placeholder={`${t("Device")}...`}
                              className="search-filter-category-type"
                              classNamePrefix="name-prefix"
                              initialValue={deviceSearch || null}
                              onChangeSelect={(e: any) => setDeviceSearch(e)}
                              isHasOptionAll={true}
                              optionAll={{ label: t('All Device'), value: '' }}
                            />
                          </Col>
                        </Row>

                        <Row>
                          <Col sm={12} md={12} lg={6} xl={3} className='mt-3'
                          >
                            <DropdownOption
                              name="format"
                              dataList={listOS || []}
                              placeholder={`${t("OS")}...`}
                              className="search-filter-category-type"
                              classNamePrefix="name-prefix"
                              initialValue={osSearch || null}
                              onChangeSelect={(e: any) => setOsSearch(e)}
                              isHasOptionAll={true}
                              optionAll={{ label: t('All OS'), value: '' }}
                            />
                          </Col>

                          <Col sm={12} md={12} lg={6} xl={3} className="mt-3">
                            <Input
                              type="text"
                              className="form-control search"
                              placeholder={`${t('UUID')}...`}
                              value={userUUIDSearch}
                              onChange={(e) => setUserUUIDSearch(e.target.value)}
                              onKeyDown={(e) => {
                                if (e.key === "Enter") {
                                  searchData();
                                }
                              }}
                            />
                          </Col>
                          <Col sm={12} md={12} lg={6} xl={3} className="date-picker-wrapper-custom mt-3">
                            <DatePickerCustom
                              placeholder={`${t('Start Date')} - ${t('End Date')}`}
                              startDate={startDate || null}
                              endDate={endDate || null}
                              onChangePicker={handleChangePicker}
                            />
                          </Col>
                          <Col sm={12} md={12} lg={12} xl={4} xxl={3} className="hstack gap-1 mt-sm-4 mt-md-3 justify-content-start">
                            <button
                              type="button"
                              className="btn btn-primary me-1"
                              onClick={searchData}
                              disabled={isDeviceLoading}
                            >
                              <i className="ri-search-line align-bottom me-1"></i>{" "}
                              {t('Button Search')}
                            </button>
                            <button
                              type="button"
                              className="btn btn-secondary fs-14"
                              onClick={resetData}
                            >
                              <i className="ri-refresh-line align-bottom me-1"></i>{" "}
                              {t('Button Reset')}
                            </button>
                          </Col>
                        </Row>
                      </div>

                    </div>

                  </CardHeader>
                  <CardBody className="pt-3">
                    <div>
                      <TableContainer
                        className="custom-header-css"
                        divClass="table-responsive table-card"
                        tableClass="align-middle"
                        theadClass="table-light"
                        columns={columns}
                        data={devices?.list?.length ? devices?.list : []}
                        customPageSize={query.limit}
                        customPageIndex={query.page - 1}
                        totalRecords={devices?.pagination?.total}
                        customPageCount={Math.ceil(Number(devices?.pagination?.total) / Number(devices?.pagination?.limit))}
                        handleChangePage={handleChangePage}
                        manualSorting={true}
                        sorting={{ sort_by: query.sort_by, order_by: query.order_by }}
                        handleChangeSorting={handleChangeSorting}
                        isLoading={isDeviceLoading}
                      />
                    </div>
                    <ToastContainer closeButton={false} limit={1} />
                  </CardBody>
                </Card>
              </Col>
            )}</Row>
        </Container>
        <Modal isOpen={!!previewDetail} centered={true} size="xl" scrollable={true} toggle={onClosePreviewDetailClick} keyboard={true}>
          <ModalHeader toggle={onClosePreviewDetailClick}>
            {t('Detail')}
          </ModalHeader>
          <ModalBody className="">
            <div className="row g-4">
              <Col md={12}>
                <div>
                  <div className="row g-3">
                    <Col xxl={12} className="mt-0">
                      <div className="tab-content border border-bottom-0">
                        <div className="table-responsive">
                          <table className="table mb-0">
                            <tbody>
                              <tr>
                                <td style={{ width: '140px' }}>{t('WEB_id')}</td>
                                <td>
                                  {previewDetail?.id}
                                </td>
                              </tr>
                              <tr>
                                <td style={{ width: '140px' }}>{t('WEB_userUUID')}</td>
                                <td>
                                  {previewDetail?.uuid}
                                </td>
                              </tr>
                              <tr>
                                <td style={{ width: '140px' }}>{t('WEB_cookies')}</td>
                                <td>
                                  {previewDetail?.cookie}
                                </td>
                              </tr>
                              <tr>
                                <td style={{ width: '140px' }}>{t('WEB_platform')}</td>
                                <td>
                                  {previewDetail?.platform}
                                </td>
                              </tr>
                              <tr>
                                <td style={{ width: '140px' }}>{t('WEB_device')}</td>
                                <td>
                                  {t(previewDetail?.device_name)}
                                </td>
                              </tr>
                              <tr>
                                <td style={{ width: '140px' }}>{t('Browser')}</td>
                                <td>
                                  {previewDetail?.browser}
                                </td>
                              </tr>
                              <tr>
                                <td style={{ width: '140px' }}>{t('WEB_ip')}</td>
                                <td>
                                  {previewDetail?.ip}
                                </td>
                              </tr>
                              <tr>
                                <td style={{ width: '140px' }}>{t('WEB_userAgent')}</td>
                                <td>
                                  {previewDetail?.user_agent}
                                </td>
                              </tr>
                              <tr>
                                <td style={{ width: '140px' }}>{t('Media')}</td>
                                <td>
                                  <div className="text-start">
                                    <div className="text-start">
                                      {previewDetail?.media?.name}
                                    </div>
                                    {showListDomain(previewDetail?.media?.domain, previewDetail?.hostname)}
                                    {/* <Link className='text-normal' to={String(previewDetail?.media?.domain || '').includes('https://') ? (previewDetail?.media?.domain || '') : `https://${previewDetail?.media?.domain}`} target="_blank">
                                      {previewDetail?.media?.domain || ''}
                                    </Link> */}
                                  </div>
                                </td>
                              </tr>
                              <tr>
                                <td style={{ width: '140px' }}>{t('status')}</td>
                                <td>
                                  <span className={`badge bg-${COLORS_STATUS[previewDetail?.status]}`}> {t(previewDetail?.status)}</span>
                                </td>
                              </tr>
                              <tr>
                                <td style={{ width: '140px' }}>{t('Created at')}</td>
                                <td>
                                  {previewDetail?.created_at}
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </Col>
                    <div className="col-lg-12 mb-4">
                      <div className="hstack gap-2 justify-content-end">
                        <button className="btn btn-soft-secondary fs-14" type="button" color="light" onClick={onClosePreviewDetailClick}>
                          <i className="ri-indeterminate-circle-line align-bottom me-1"></i>
                          {t('Button Close')}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </Col>
            </div>
          </ModalBody>
        </Modal >
      </div >
    </React.Fragment >
  );
};

export default DeviceList;
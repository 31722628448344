import { createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

//Include Both Helper File with needed methods
import notificationApi from 'api/notificationApi';
import { formatQueryParams } from "helpers/format";

export const getNotifications = createAsyncThunk("Notifications" , async (params: any = {}) => {
  try{
    const response = await notificationApi.notifications(formatQueryParams(params))
    return response;
  }catch (error) {
    return error;
  }
});

export const getNotification =  async(id: string | number ,  params: any = {}) => {
  try{
    const response = await notificationApi.notification(id, params)
    return response;
  }catch (error) {
    return error;
  }
};

export const getNotificationLogsByNormal = async (params: any = {}) => {
  if (params && params.time_request) {
    delete params["time_request"];
  }

  try {
    const response = await notificationApi.notifications(params);
    return response;
  } catch (error) {
    return error;
  }
};

export const postNotification = async (data: any = {}) => {
  try{
    const response = await notificationApi.postNotification(data);
    return response;
  }catch (error) {
    return error;
  }
};

export const putNotification = async (id: string | number , data: any = {}) => {
  try{
    const response = await notificationApi.putNotification(id, data);
    return response;
  }catch (error) {
    return error;
  }
};

export const deleteNotification = async (id: string | number , data: any = {}) => {
  try{
    const response = await notificationApi.deleteNotification(id, data);
    return response;
  }catch (error) {
    return error;
  }
};

export const getTransmissionList = createAsyncThunk("TransmissionList" , async (params: any = {}) => {
  try{
    const response = await notificationApi.transmissionList(formatQueryParams(params))
    return response;
  }catch (error) {
    return error;
  }
});

export const getNotificationClickList = async (params: any = {}) => {
  try {
    const response = await notificationApi.notificationClickList(
      formatQueryParams(params)
    );
    return response;
  } catch (error) {
    return error;
  }
};


export const getNotificationsListAllOS = async () => {
  try {
    const response = await notificationApi.getNotificationsListAllOS();
    return response;
  } catch (error) {
    return error;
  }
};


export const getNotificationsListAllDevice = async () => {
  try {
    const response = await notificationApi.getNotificationsListAllDevice();
    return response;
  } catch (error) {
    return error;
  }
};
import React, { useEffect, useRef, useState } from "react";
import { Tooltip, UncontrolledTooltip } from 'reactstrap';

const TooltipCustom = ({
    title = '',
    id = '',
    children
}: any) => {
    const ref = useRef(null);
    return (
        <React.Fragment>
            <li ref={ref}>
                {children}
            </li>
            <UncontrolledTooltip
                target={ref}
            >
                {title}
            </UncontrolledTooltip>
        </React.Fragment>
    );
};

export default TooltipCustom;
import { AxiosResponse } from "axios";
import { APIClient } from "./axiosClient";
import { ResponseData } from "./types/_public";
const api = new APIClient();

const path = "/upload";
const UploadApi = {
  uploadFile(dataForm: any): Promise<AxiosResponse<ResponseData<any>, any>> {
    const url = `${path}/files`;
    const formData = new FormData();
    formData.append('files', dataForm ? dataForm as File : '');

    return api.post(
      url,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
          "x-ms-blob-type": "BlockBlob",
        },
      }
    );
  },
};

export default UploadApi;

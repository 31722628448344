import { createSlice } from "@reduxjs/toolkit";
import { IDevice } from "api/types/_device";
import { PaginationResponse, ResponseData } from "api/types/_public";
import { getDevices } from "./thunk";


export interface IState {
  devices: (ResponseData<IDevice[]> & PaginationResponse) | null;
  isDeviceLoading: boolean;
  isDeviceSuccess: boolean;

  allDevice: ResponseData<IDevice[]> | null;

  error: any;
};

export const initialState: IState = {
  devices: null,
  isDeviceLoading: false,
  isDeviceSuccess: false,

  allDevice: null,

  error: {},
};

const DeviceSlice = createSlice({
  name: "Device",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    //get Devices
    builder.addCase(getDevices.pending, (state: IState, action: any) => {
      state.isDeviceLoading = true
    });
    builder.addCase(getDevices.fulfilled, (state: IState, action: any) => {
      state.devices = action.payload.data;
      state.isDeviceSuccess = true;
      state.isDeviceLoading = false;
    });
    builder.addCase(getDevices.rejected, (state: IState, action: any) => {
      state.error = action.payload.error || null;
      state.isDeviceSuccess = false;
      state.isDeviceLoading = false;
    });
  },
});

export default DeviceSlice.reducer;

import React from "react";
import { Input } from "reactstrap";

interface InputsRangeProps {
  nameFrom?: string,
  nameTo?: string,
  valueFrom?: string,
  valueTo?: string,
  placeholderFrom?: string,
  placeholderTo?: string,
  onChangeFrom?: (e: any) => void;
  onChangeTo?: (e: any) => void;
  onKeyDownFrom?: (e: any) => void;
  onKeyDownTo?: (e: any) => void;
}

const InputsRange = ({
  nameFrom = '',
  nameTo = '',
  valueFrom = '',
  valueTo = '',
  placeholderFrom = '',
  placeholderTo = '',
  onChangeFrom,
  onChangeTo,
  onKeyDownFrom,
  onKeyDownTo,
}: InputsRangeProps) => {

  return (
    <React.Fragment>
      <div className="d-flex">
        <div className="position-relative w-100">
          <Input
            name={nameFrom || ''}
            type="text"
            autoComplete="off"
            className="form-control search"
            placeholder={placeholderFrom || ''}
            value={valueFrom}
            onChange={(e) => { onChangeFrom && onChangeFrom(e.target.value) }}
            onKeyDown={(e) => { onKeyDownFrom && onKeyDownFrom(e) }}
          />
          <span
            className={`mdi mdi-close-circle search-widget-icon search-widget-icon-close position-absolute cursor-pointer  ${!valueFrom && 'd-none'}`}
            onClick={(e) => { onChangeFrom && onChangeFrom('') }}
            style={{
              right: '8px',
              top: 5,
              fontSize: '20px',
              color: 'var(--vz-header-item-sub-color)'
            }}
          ></span>
        </div>

        <div className="p-2">
          →
        </div>
        <div className="position-relative w-100">
          <Input
            name={nameTo || ''}
            type="text"
            autoComplete="off"
            className="form-control search"
            placeholder={placeholderTo || ''}
            value={valueTo}
            onChange={(e) => { onChangeTo && onChangeTo(e.target.value) }}
            onKeyDown={(e) => { onKeyDownTo && onKeyDownTo(e) }}
          />
          <span
            className={`mdi mdi-close-circle search-widget-icon search-widget-icon-close position-absolute cursor-pointer ${!valueTo && 'd-none'}`}
            onClick={(e) => { onChangeTo && onChangeTo('') }}
            style={{
              right: '8px',
              top: 5,
              fontSize: '20px',
              color: 'var(--vz-header-item-sub-color)'
            }}
          ></span>
        </div>

      </div>
    </React.Fragment>
  );
};

export default InputsRange;

import { IRole } from 'api/types/_role';
import { IUser } from 'api/types/_user';
import { CONFIG_OPTION_TOAST_ERROR, CONFIG_OPTION_TOAST_NORMAL } from 'common/toast';
import TooltipCustom from 'components/Common/TooltipCustom';
import { useRole } from 'components/Hooks/UserHooks';
import en from 'date-fns/locale/en-US';
import ko from 'date-fns/locale/ko';
import { ROLES_FOR_APP, isHavePermissionRole } from 'helpers/role';
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Card, CardBody, CardHeader, Col, Container, Input, Modal, ModalBody, ModalHeader, Row } from "reactstrap";
import { createSelector } from "reselect";
import { NumberParam, StringParam, useQueryParams, withDefault, } from "use-query-params";
import BreadCrumb from "../../../components/Common/BreadCrumb";
import TableContainer from "../../../components/Common/TableContainer";
import { getAllRoles, getUsers as onGetUsers, putUser } from "../../../store/thunks";
import UserForm from './UserForm';


interface Option {
  label: string;
  value: string;
}

export interface Tag {
  id: string;
  text: string;
}

const TYPE_SELECT_DEFAULT: string = 'id';

registerLocale('en', en);
registerLocale('ko', ko);


const UserList = () => {
  const { t, i18n } = useTranslation();
  const { userPermissions } = useRole();
  const navigate = useNavigate();
  const [query, setQuery]: any = useQueryParams({
    time_request: withDefault(NumberParam, 0),
    page: withDefault(NumberParam, 1),
    limit: withDefault(NumberParam, 30),
    sort_by: withDefault(StringParam, TYPE_SELECT_DEFAULT),
    email: withDefault(StringParam, ''),
  });
  const [keywordSearch, setKeywordSearch] = useState<string>(query?.keyword || "");

  const [mediasSearch, setMediasSearch] = useState<Option[]>([]);

  const [userEdit, setUserEdit] = useState<IUser & { isCopy?: boolean } | null>(null);

  const [listRole, setListRole] = useState<IRole[]>([]);

  const [isOpen, setIsOpen] = useState<boolean>(false);

  const dispatch: any = useDispatch();

  const selectLayoutState = (state: any) => state.User;

  const UserProperties = createSelector(
    selectLayoutState,
    (state) => ({
      users: state.users,
      isUserSuccess: state.isUserSuccess,
      isUserLoading: state.isUserLoading,
      error: state.error,
    })
  );

  // Inside your component
  const { users, error, isUserLoading } = useSelector(UserProperties);


  useEffect(() => {
    dispatch(onGetUsers(query));
  }, [dispatch, JSON.stringify(query)]);

  const searchData = () => {
    setQuery({
      ...query,
      email: keywordSearch || undefined,
      sort_by: TYPE_SELECT_DEFAULT,
      page: 1,
      time_request: + new Date()
    });
  };

  const resetData = () => {
    setQuery({
      email: undefined,
      sort_by: TYPE_SELECT_DEFAULT,
      time_request: + new Date()
    }, "push")
    setKeywordSearch((_prev) => "");
    setMediasSearch([]);
  };


  // Begin::Update User
  const onCloseClick = () => {
    setUserEdit((_prev) => null);
    setIsOpen((_prev) => false)
  }

  const triggerRefresh = () => {
    onCloseClick();
    dispatch(onGetUsers(query));
  }
  //End::Update User


  // Column
  const columns = useMemo(
    () => [
      {
        Header: t('Id'),
        accessor: "id",
        filterable: true,
        sortable: false,
        Cell: (cell: any) => (
          <>
            <span>{cell?.value}</span>
          </>
        ),
      },
      {
        Header: t('Name'),
        accessor: "first_name",
        filterable: true,
        sortable: false,
        Cell: (cell: any) => {
          const item = cell?.row?.original;
          return (<>
            <div>
              {item?.first_name || ''} {item?.last_name || ''}
            </div>
          </>)
        },
      },
      {
        Header: t('Username'),
        accessor: "username",
        filterable: true,
        sortable: false,
        Cell: (cell: any) => {
          const item = cell?.row?.original;
          return (<>
            <div>{cell?.value}</div>
          </>)
        },
      },
      {
        Header: t('Email'),
        accessor: "email",
        filterable: true,
        sortable: false,
        Cell: (cell: any) => {
          const item = cell?.row?.original;
          return (<>
            <div>{cell?.value}</div>
          </>)
        },
      },
      {
        Header: t('Role'),
        accessor: "role",
        filterable: true,
        sortable: false,
        Cell: (cell: any) => {
          const item = cell?.row?.original;
          return (<>
            <div>{item?.role?.name || ''}</div>
          </>)
        },
      },
      {
        Header: t('Active'),
        accessor: "is_active",
        filterable: false,
        sortable: false,
        Cell: (cell: any) => {
          const value: any = cell?.row?.original;
          const active = value?.is_active;
          return (
            <div className="form-check form-switch form-switch-sm" dir="ltr">
              <input type="checkbox" className="form-check-input" name="is_active" id="customSwitchsizelg" checked={Boolean(active)}
                onChange={(event: any) => {
                  if (isHavePermissionRole(ROLES_FOR_APP.USER_UPDATE, userPermissions)) {
                    handleChangeStatusItem(value, event.target.checked)
                  }
                }}
              />
            </div>
          );
        }
      },
      {
        Header: t('Created at'),
        accessor: "created_at",
        filterable: true,
        sortable: false,
        thWidth: 100,
        Cell: (cell: any) => {
          const arrDate = String(cell?.value || '').split(' ');
          return (
            <>
              <span><span>{arrDate?.[0] || ''}</span> <br /> <span className="text-secondary">{arrDate?.[1] || ''}</span> </span>
            </>
          )
        },
      },
      {
        Header: t('Updated at'),
        accessor: "updated_at",
        filterable: true,
        sortable: false,
        thWidth: 100,
        Cell: (cell: any) => {
          const arrDate = String(cell?.value || '').split(' ');
          return (
            <>
              <span><span>{arrDate?.[0] || ''}</span> <br /> <span className="text-secondary">{arrDate?.[1] || ''}</span> </span>
            </>
          )
        },
      },
      {
        Header: t('Button Action'),
        thClass: 'text-center',
        thWidth: 90,
        Cell: (cell: any) => {
          const item: any = cell?.row?.original;
          return (
            <ul className="list-inline flex-wrap justify-content-center hstack gap-2 mb-0">
              {isHavePermissionRole(ROLES_FOR_APP.USER_DELETE, userPermissions) && <TooltipCustom
                title={t('Button Update User')}
                id={`update-us-${item?.id}`}
              >
                <li className=" list-inline-item me-0" id={`update-us-${item?.id}`}>
                  <Link className="btn btn-sm btn-soft-secondary edit-item-btn" to="#"
                    onClick={(e) => { e.preventDefault(); setUserEdit((prev) => item) }}
                  >
                    <i className="ri-pencil-fill align-bottom"></i>
                  </Link>
                </li>
              </TooltipCustom>}
            </ul>
          );
        },
      },
    ],
    [i18n?.language, query, userPermissions]
  );

  const handleChangePage = useCallback((page: any) => {
    setQuery({ page: page + 1 })
  }, []);

  const handleChangeSorting = useCallback((sortBy: any) => {
    setQuery((_prev: any) => {
      return ({ ..._prev, ...sortBy });
    });
  }, []);

  const handleCallAllOption = async () => {
    try {
      const [resRole]: any = await Promise.all([getAllRoles()]);
      if (resRole?.data) {
        setListRole((_prev) => resRole?.data);
      }
    } catch (error: any) {
      return error;
    }
  };
  const handleChangeStatusItem = async (item: any, status: boolean) => {
    try {
      const { email, fist_name, last_name, role_id } = structuredClone(item);
      const data = {
        email,
        fist_name,
        last_name,
        role_id,
        is_active: Number(status),
      };
      const response: any = await putUser(item?.id, data);
      if (response?.data) {
        dispatch(onGetUsers(query));
        toast(`${t('The process has been completed.')}`, CONFIG_OPTION_TOAST_NORMAL);
      } else {
        toast(`${response}`, CONFIG_OPTION_TOAST_ERROR);
      }
    } catch (error: any) {
      toast(`${error?.message || ''}`, CONFIG_OPTION_TOAST_ERROR);
      return error;
    }
  }

  useEffect(() => {
    handleCallAllOption();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    document.title = `${t('User')} - ${t('User')} | MessageHub`;
    document.body.classList.remove('vertical-sidebar-enable');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [i18n?.language]);

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title={t('User')} pageTitle={t('User')} />
          <Row>
            {isHavePermissionRole(ROLES_FOR_APP.USER_LIST, userPermissions) && (
              <Col lg={12}>
                <Card id="leadsList">
                  <CardHeader className="border-0">
                    <Row className="g-4 align-items-center mb-2 mt-2">
                      <Col sm={12} lg={3} className='mt-2'>
                        <Input
                          type="text"
                          className="form-control search"
                          placeholder={`${t('Email')}...`}
                          value={keywordSearch}
                          onChange={(e) => setKeywordSearch(e.target.value)}
                          onKeyDown={(e) => {
                            if (e.key === "Enter") {
                              searchData();
                            }
                          }}
                        />
                      </Col>
                      <Col sm={12} lg={9} className="hstack gap-1 justify-content-sm-center justify-content-md-between mt-sm-4 mt-md-2">
                        <div>
                          <button
                            type="button"
                            className="btn btn-primary me-1"
                            onClick={searchData}
                            disabled={isUserLoading}
                          >
                            <i className="ri-search-line align-bottom me-1"></i>{" "}
                            {t('Button Search')}
                          </button>
                          <button
                            type="button"
                            className="btn btn-secondary fs-14"
                            onClick={resetData}
                          >
                            <i className="ri-refresh-line align-bottom me-1"></i>{" "}
                            {t('Button Reset')}
                          </button>
                        </div>
                        {isHavePermissionRole(ROLES_FOR_APP.USER_CREATE, userPermissions) && (<button
                          type="button"
                          className="btn btn-success me-1"
                          onClick={() => setIsOpen((_prev) => true)}
                        >
                          <i className="ri-add-fill align-bottom me-1"></i>{" "}
                          {t("Button Create User")}
                        </button>)}
                      </Col>
                    </Row>
                  </CardHeader>
                  <CardBody className="pt-3">
                    <div>
                      <TableContainer
                        className="custom-header-css"
                        divClass="table-responsive table-card"
                        tableClass="align-middle"
                        theadClass="table-light"
                        columns={columns}
                        data={users?.list?.length ? users?.list : []}
                        customPageSize={query.limit}
                        customPageIndex={query.page - 1}
                        totalRecords={users?.pagination?.total}
                        customPageCount={Math.ceil(Number(users?.pagination?.total) / Number(users?.pagination?.limit))}
                        handleChangePage={handleChangePage}
                        manualSorting={true}
                        sorting={{ sort_by: query.sort_by, order_by: query.order_by }}
                        handleChangeSorting={handleChangeSorting}
                        isLoading={isUserLoading}
                      />
                    </div>
                    <ToastContainer closeButton={false} limit={1} />
                  </CardBody>
                </Card>
              </Col>
            )}</Row>
        </Container>
        <Modal
          isOpen={!!userEdit?.id || isOpen}
          centered={true}
          size="xl"
          scrollable={true}
        >
          <ModalHeader toggle={onCloseClick}>
            <>{!!(userEdit?.id) ? t("Button Update User") : t("Button Create User")}</>  <>{(userEdit?.id) && (' - ' + (userEdit?.first_name || '') + (userEdit?.last_name || '') || '')}</>
          </ModalHeader>
          <ModalBody>
            <div style={{ minHeight: "calc(100dvh - 200px)" }}>
              <UserForm
                isModal={true}
                id={String(userEdit?.id || '')}
                isCopy={!!(userEdit?.isCopy)}
                triggerRefresh={triggerRefresh}
                listRole={listRole}
              />
            </div>
          </ModalBody>
        </Modal>
      </div >
    </React.Fragment >
  );
};

export default UserList;
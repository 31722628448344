import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Input } from 'reactstrap';
//SimpleBar
import SimpleBar from "simplebar-react";

//import images
import { useTranslation } from 'react-i18next';
import { useRole } from 'components/Hooks/UserHooks';
import { ROLES_FOR_APP, isHavePermissionRole } from 'helpers/role';

const SearchOptionPages = () => {
    const { t } = useTranslation();
    const { userPermissions } = useRole();
    const [value, setValue] = useState("");
    const onChangeData = (value: any) => {
        setValue(value);
    };

    const menuItems: any = [
        {
            label: t('Dashboards'),
            link: "/dashboard",
            icon: <i className=" ri-dashboard-line align-middle fs-18 text-muted me-2"></i>,
            isShow: isHavePermissionRole(ROLES_FOR_APP.DASHBOARD_VIEW, userPermissions),
        },
        // {
        //     label: t('User'),
        //     link: "/user/list",
        //     icon: <i className="ri-group-line align-middle fs-18 text-muted me-2"></i>,
        //     isShow: isHavePermissionRole(ROLES_FOR_APP.USER_LIST, userPermissions),
        // },
        // {
        //     label: t('Profile'),
        //     link: "/user/profile",
        //     icon: <i className="ri-user-6-line align-middle fs-18 text-muted me-2"></i>,
        //     isShow: true,
        // },
        // {
        //     label: t('Role'),
        //     link: "/user/role",
        //     icon: <i className="ri-user-star-line align-middle fs-18 text-muted me-2"></i>,
        //     isShow: isHavePermissionRole(ROLES_FOR_APP.ROLE_LIST, userPermissions),
        // },
        {
            label: t('Media'),
            link: "/media/list",
            icon: <i className="ri-building-2-line align-middle fs-18 text-muted me-2"></i>,
            isShow: isHavePermissionRole(ROLES_FOR_APP.MEDIA_LIST, userPermissions),
        },
        {
            label: t('Create Campaign'),
            link: "/campaign/create",
            icon: <i className="ri-bookmark-line align-middle fs-18 text-muted me-2"></i>,
            isShow: isHavePermissionRole(ROLES_FOR_APP.CAMPAIGN_CREATE, userPermissions),
        },
        {
            label: t('Campaign'),
            link: "/campaign/list",
            icon: <i className="ri-bookmark-line align-middle fs-18 text-muted me-2"></i>,
            isShow: isHavePermissionRole(ROLES_FOR_APP.CAMPAIGN_LIST, userPermissions),
        },
        {
            label: t('Notification Log'),
            link: "/statistics/notification-log",
            icon: <i className="ri-bar-chart-grouped-fill align-middle fs-18 text-muted me-2"></i>,
            isShow: isHavePermissionRole(ROLES_FOR_APP.MEDIA_STATISTICS_LIST, userPermissions),
        },
        {
            label: t('Campaign Statistics'),
            link: "/statistics/transmission-list",
            icon: <i className="ri-bar-chart-grouped-fill align-middle fs-18 text-muted me-2"></i>,
            isShow: isHavePermissionRole(ROLES_FOR_APP.MEDIA_STATISTICS_LIST, userPermissions),
        },
        {
            label: t('Device'),
            link: "/media/deivce",
            icon: <i className="ri-building-2-line align-middle fs-18 text-muted me-2"></i>,
            isShow: isHavePermissionRole(ROLES_FOR_APP.DEVICE_LIST, userPermissions),
        }
    ];

    useEffect(() => {
        var searchOptions: any = document.getElementById("search-close-options");
        var dropdown: any = document.getElementById("search-dropdown");
        var searchInput: any = document.getElementById("search-options");

        searchInput.addEventListener("focus", function () {
            var inputLength = searchInput.value.length;
            if (inputLength > 0) {
                dropdown.classList.add("show");
                searchOptions.classList.remove("d-none");
            } else {
                dropdown.classList.remove("show");
                searchOptions.classList.add("d-none");
            }
        });

        searchInput.addEventListener("keyup", function () {
            var inputLength = searchInput.value.length;
            if (inputLength > 0) {
                dropdown.classList.add("show");
                searchOptions.classList.remove("d-none");
            } else {
                dropdown.classList.remove("show");
                searchOptions.classList.add("d-none");
            }
        });

        searchOptions.addEventListener("click", function () {
            searchInput.value = "";
            dropdown.classList.remove("show");
            searchOptions.classList.add("d-none");
        });

        document.body.addEventListener("click", function (e: any) {
            if (e.target.getAttribute('id') !== "search-options") {
                dropdown.classList.remove("show");
                searchOptions.classList.add("d-none");
            }
        });
    }, []);

    const checkIsIncludeText = (text: string, search: string) => {
        return String(text).toUpperCase().includes(String(search).toUpperCase());
    };

    return (
        <React.Fragment>
            <form className="app-search d-none d-sm-block">
                <div className="position-relative">
                    <Input type="text" className="form-control" placeholder={`${t('Button Search')}...`}
                        id="search-options"
                        autoComplete="off"
                        value={value}
                        onChange={e => {
                            onChangeData(e.target.value);
                        }} />
                    <span className="mdi mdi-magnify search-widget-icon"></span>
                    <span className="mdi mdi-close-circle search-widget-icon search-widget-icon-close d-none"
                        id="search-close-options"></span>
                </div>
                <div className="dropdown-menu dropdown-menu-lg" id="search-dropdown">
                    <SimpleBar style={{ height: "320px" }}>

                        <div className="dropdown-header mt-2">
                            <h6 className="text-overflow text-muted mb-1 text-uppercase">{t('Pages')}</h6>
                        </div>

                        {menuItems?.map((item: any, index: number) => (
                            <Link key={index} to={item?.link} className={`dropdown-item notify-item ${(!!item?.isShow && checkIsIncludeText(item?.label || '', value || '')) ? '' : 'd-none'}`}>
                                {item?.icon}
                                <span className="">{item?.label}</span>
                            </Link>
                        ))}
                    </SimpleBar>
                </div>
            </form>
        </React.Fragment>
    );
};

export default SearchOptionPages;
import { createAsyncThunk } from "@reduxjs/toolkit";
import newsApi from "api/newsApi";
import { formatQueryParams } from "helpers/format";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

//Include Both Helper File with needed methods


export const getNews = createAsyncThunk("news" , async (params: any = {}) => {
  try{
    const response = await newsApi.news(formatQueryParams(params))
    return response;
  }catch (error) {
    return error;
  }
});


export const previewNews = async (data: any = {}) => {
  try{
    const response = await newsApi.preview(data);
    return response;
  }catch (error) {
    return error;
  }
};

export const detailNews = async (id: string | number , data: any = {}) => {
  try{
    const response = await newsApi.detailNews(id, data);
    return response;
  }catch (error) {
    return error;
  }
};

export const postNews = async (data: any = {}) => {
  try{
    const response = await newsApi.postNews(data);
    return response;
  }catch (error) {
    return error;
  }
};

export const putNews = async (id: string | number , data: any = {}) => {
  try{
    const response = await newsApi.putNews(id, data);
    return response;
  }catch (error) {
    return error;
  }
};

export const putReCreateNews = async (id: string | number , data: any = {}) => {
  try{
    const response = await newsApi.putReCreateNews(id, data);
    return response;
  }catch (error) {
    return error;
  }
};

export const putMultiReCreateNews = async (data: any = {}) => {
  try{
    const response = await newsApi.putMultiReCreateNews(data);
    return response;
  }catch (error) {
    return error;
  }
};


export const putResetStatusNews = async (id: string | number , data: any = {}) => {
  try{
    const response = await newsApi.putResetStatusNews(id, data);
    return response;
  }catch (error) {
    return error;
  }
};

export const putMultiResetStatusNews = async (data: any = {}) => {
  try{
    const response = await newsApi.putMultiResetStatusNews(data);
    return response;
  }catch (error) {
    return error;
  }
};



export const putConfirmNews = async (id: string | number , data: any = {}) => {
  try{
    const response = await newsApi.putConfirmNews(id, data);
    return response;
  }catch (error) {
    return error;
  }
};

export const deleteNews = async (id: string | number , data: any = {}) => {
  try{
    const response = await newsApi.deleteNews(id, data);
    return response;
  }catch (error) {
    return error;
  }
};

export const downloadFileNews = async (id: string | number , data: any = {}) => {
  try{
    const response = await newsApi.downloadFile(id, data);
    return response;
  }catch (error) {
    return error;
  }
};

export const downloadImageNews = async (id: string | number , data: any = {}) => {
  try{
    const response = await newsApi.downloadImageNews(id, data);
    return response;
  }catch (error) {
    return error;
  }
};

export const getScriptTrackingNews = async (id: string | number , data: any = {}) => {
  try{
    const response = await newsApi.renderScriptTrackingNews(id, data);
    return response;
  }catch (error) {
    return error;
  }
};

export const uploadImage = async (data: any = {}) => {
  try{
    const response = await newsApi.uploadImage(data);
    return response;
  }catch (error) {
    return error;
  }
};



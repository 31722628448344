import classnames from "classnames";
import {
    CONFIG_OPTION_TOAST_ERROR,
    CONFIG_OPTION_TOAST_NORMAL,
} from "common/toast";
import en from "date-fns/locale/en-US";
import ko from "date-fns/locale/ko";
import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useTranslation } from "react-i18next";
import "react-quill/dist/quill.snow.css";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Card, CardBody, CardHeader, Col, Container, Input, Label, Nav, NavItem, NavLink, Row, Spinner, TabContent, TabPane } from 'reactstrap';
import {
    putChangePasswordProfile,
    putProfile
} from "store/thunks";
import * as Yup from "yup";

//import images
import { IUser } from "api/types/_user";
import { useSelector } from "react-redux";
import progileBg from '../../../assets/images/profile-bg.jpg';
import avatar1 from '../../../assets/images/users/user-dummy-img.jpg';

export interface Tag {
    id: string;
    text: string;
}

export interface Props {
}

registerLocale("en", en);
registerLocale("ko", ko);

const Profile = ({ }: Props) => {
    const { t, i18n } = useTranslation();
    const navigate = useNavigate();

    const user: any = useSelector((state: any) => state?.Login);

    const id = user?.user?.user?.id;

    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isLoadingDetail, setIsLoadingDetail] = useState<boolean>(false);

    const [initialValuesDefault, setInitialValuesDefault] = useState<IUser | null>(user?.user || null);

    const [activeTab, setActiveTab] = useState("1");
    const [textExperience, setTextExperience] = useState("You always want to make sure that your fonts work well together and try to limit the number of fonts you use to three or less. Experiment and play around with the fonts that you already have in the software you're working with reputable font websites. ");


    const tabChange = (tab: any) => {
        if (activeTab !== tab) setActiveTab(tab);
    };

    const handleSubmit = async (values: any) => {

        try {
            setIsLoading((_prev) => true);
            const data = {
                first_name: values?.first_name ?? "",
                last_name: values?.last_name ?? "",
                email: values?.email ?? "",
            };
            const response: any = await putProfile(data);
            if (response?.data) {
                setIsLoading((_prev) => false);
                toast(
                    `${t("The process has been completed.")}`,
                    CONFIG_OPTION_TOAST_NORMAL
                );
            } else {
                setIsLoading((_prev) => false);
                toast(`${response}`, CONFIG_OPTION_TOAST_ERROR);
            }
        } catch (error: any) {
            setIsLoading((_prev) => false);
            toast(`${error?.message || ""}`, CONFIG_OPTION_TOAST_ERROR);
            return error;
        }
    };

    const validationSchema = Yup.object({
        first_name: Yup.string().required(`${t("First Name is required")}`),
        last_name: Yup.string().required(`${t("Last Name is required")}`),
        email: Yup.string().email('Please enter a valid email address').required(`${t('Email is required')}`),
        joining_date: Yup.string().nullable(),
    });

    const formik = useFormik({
        initialValues: {
            first_name: "",
            last_name: "",
            email: "",
            joining_date: "",
        },
        validationSchema,
        onSubmit: handleSubmit,
    });

    const handleSetValueForm = (valueDefault: any) => {
        const vFirstName = valueDefault?.first_name || '';
        const vLastName = valueDefault?.last_name || '';
        const vEmail = valueDefault?.email || '';
        const vJoiningDate = valueDefault?.created_at || '';

        setTimeout(() => {
            formik.setFieldValue("first_name", vFirstName);
            formik.setFieldValue("last_name", vLastName);
            formik.setFieldValue("email", vEmail);
            formik.setFieldValue("joining_date", vJoiningDate);
        }, 300);
    };

    const handleSubmitChangePassword = async (values: any) => {

        try {
            setIsLoading((_prev) => true);
            const data = {
                old_password: values?.old_password ?? "",
                new_password: values?.new_password ?? "",
            };
            const response: any = await putChangePasswordProfile(data);
            if (response?.data) {
                setIsLoading((_prev) => false);
                toast(
                    `${t("The process has been completed.")}`,
                    CONFIG_OPTION_TOAST_NORMAL
                );
            } else {
                setIsLoading((_prev) => false);
                toast(`${response}`, CONFIG_OPTION_TOAST_ERROR);
            }
        } catch (error: any) {
            setIsLoading((_prev) => false);
            toast(`${error?.message || ""}`, CONFIG_OPTION_TOAST_ERROR);
            return error;
        }
    };

    const validationSchemaChangePassword = Yup.object({
        old_password: Yup.string().required(`${t("Old Password is required")}`),
        new_password: Yup.string().required(`${t("News Password is required")}`),
        confirm_password: Yup.string().required(`${t("Confirm Password is required")}`),
    });

    const formikChangePassword = useFormik({
        initialValues: {
            old_password: "",
            new_password: "",
            confirm_password: "",
        },
        validationSchema: validationSchemaChangePassword,
        onSubmit: handleSubmitChangePassword,
    });

    const handleCallAllOption = async (idItem: string) => {
        try {
            if (!idItem) {
                return
            }
            setIsLoadingDetail((_prev) => true);
            // const [resDetail]: any = await Promise.all([idItem ? getUser(idItem) : {},]);
            // if (resDetail?.data) {
            //     setInitialValuesDefault((_prev) => resDetail?.data);
            //     handleSetValueForm(resDetail?.data);
            //     setIsLoadingDetail((_prev) => false);
            // }
        } catch (error: any) {
            setIsLoadingDetail((_prev) => false);
            return error;
        }
    };

    useEffect(() => {
        handleCallAllOption(id);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id]);

    useEffect(() => {
        document.title = `${t("User Create")} - ${t("User")} | NewsHub`;
        document.body.classList.remove("vertical-sidebar-enable");
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [i18n?.language]);

    useEffect(() => {
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [i18n?.language]);

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <div className="position-relative mx-n4 mt-n4">
                        <div className="profile-wid-bg profile-setting-img">
                            <img src={progileBg} className="profile-wid-img" alt="" />
                            <div className="overlay-content">
                                <div className="text-end p-3">
                                    <div className="p-0 ms-auto rounded-circle profile-photo-edit">
                                        <Input id="profile-foreground-img-file-input" type="file"
                                            className="profile-foreground-img-file-input" />
                                        <Label htmlFor="profile-foreground-img-file-input"
                                            className="profile-photo-edit btn btn-light">
                                            <i className="ri-image-edit-line align-bottom me-1"></i> {t('Change Cover')}
                                        </Label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Row>
                        <Col xxl={3}>
                            <Card className="mt-n5">
                                <CardBody className="p-4">
                                    <div className="text-center">
                                        <div className="profile-user position-relative d-inline-block mx-auto  mb-4">
                                            <img src={avatar1}
                                                className="rounded-circle avatar-xl img-thumbnail user-profile-image"
                                                alt="user-profile" />
                                            <div className="avatar-xs p-0 rounded-circle profile-photo-edit">
                                                <Input id="profile-img-file-input" type="file"
                                                    className="profile-img-file-input" />
                                                <Label htmlFor="profile-img-file-input"
                                                    className="profile-photo-edit avatar-xs">
                                                    <span className="avatar-title rounded-circle bg-light text-body">
                                                        <i className="ri-camera-fill"></i>
                                                    </span>
                                                </Label>
                                            </div>
                                            {isLoadingDetail && <div style={{
                                                position: 'absolute',
                                                zIndex: 3,
                                                top: 0,
                                                right: 0,
                                                bottom: 0,
                                                left: 0,
                                                borderRadius: '50%',
                                                backgroundColor: 'rgb(164 164 164 / 36%)',
                                                display: 'flex',
                                                justifyContent: 'center',
                                                alignItems: 'center',
                                            }}>
                                                <div><Spinner size="sm" color="primary"></Spinner></div>
                                            </div>}
                                        </div>
                                        <h5 className="fs-16 mb-1">{formik?.values?.first_name || ''} {formik?.values?.last_name || ''}</h5>
                                        <p className="text-muted mb-0">{initialValuesDefault?.role?.name || ''}</p>
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>

                        <Col xxl={9}>
                            <Card className="mt-xxl-n5">
                                <CardHeader>
                                    <Nav className="nav-tabs-custom rounded card-header-tabs border-bottom-0"
                                        role="tablist">
                                        <NavItem>
                                            <NavLink
                                                className={classnames({ active: activeTab === "1" })}
                                                onClick={() => {
                                                    tabChange("1");
                                                }}>
                                                {t('Personal Details')}
                                            </NavLink>
                                        </NavItem>
                                        {/* <NavItem>
                                            <NavLink
                                                className={classnames({ active: activeTab === "2" })}
                                                onClick={() => {
                                                    tabChange("2");
                                                }}>
                                                {t('Change Password')}
                                            </NavLink>
                                        </NavItem> */}
                                    </Nav>
                                </CardHeader>
                                <CardBody className="p-4">
                                    <TabContent activeTab={activeTab}>
                                        <TabPane tabId="1">
                                            <form onSubmit={formik.handleSubmit}>
                                                <Row>
                                                    <Col lg={6}>
                                                        <div className="mb-3">
                                                            <Label htmlFor="firstnameInput" className="form-label">{t('First Name')} <span className="text-danger">*</span></Label>
                                                            <Input
                                                                name="first_name"
                                                                type="text"
                                                                className="form-control"
                                                                id="firstnameInput"
                                                                placeholder={t('Enter your firstname')}
                                                                value={formik?.values?.first_name}
                                                                onChange={(event: any) => formik.setFieldValue('first_name', event?.target?.value)}
                                                                disabled
                                                            />
                                                            {formik.touched.first_name && formik.errors.first_name ? (
                                                                <div className="text-danger mt-2">{formik.errors.first_name}</div>
                                                            ) : null}
                                                        </div>
                                                    </Col>
                                                    <Col lg={6}>
                                                        <div className="mb-3">
                                                            <Label htmlFor="lastnameInput" className="form-label">{t('Last Name')} <span className="text-danger"> *</span></Label>
                                                            <Input
                                                                name="last_name"
                                                                type="text"
                                                                className="form-control"
                                                                id="lastnameInput"
                                                                placeholder={t('Enter your lastname')}
                                                                value={formik?.values?.last_name}
                                                                onChange={(event: any) => formik.setFieldValue('last_name', event?.target?.value)}
                                                                disabled
                                                            />
                                                            {formik.touched.last_name && formik.errors.last_name ? (
                                                                <div className="text-danger mt-2">{formik.errors.last_name}</div>
                                                            ) : null}
                                                        </div>
                                                    </Col>
                                                    <Col lg={6}>
                                                        <div className="mb-3">
                                                            <Label htmlFor="emailInput" className="form-label">{t('Email Address')} <span className="text-danger"> *</span></Label>
                                                            <Input
                                                                name="email"
                                                                type="text"
                                                                className="form-control"
                                                                id="emailInput"
                                                                placeholder={t('Enter your email')}
                                                                value={user?.user?.user?.email}
                                                                onChange={(event: any) => formik.setFieldValue('email', event?.target?.value)}
                                                                disabled
                                                            />
                                                            {formik.touched.email && formik.errors.email ? (
                                                                <div className="text-danger mt-2">{formik.errors.email}</div>
                                                            ) : null}
                                                        </div>
                                                    </Col>
                                                    <Col lg={6}>
                                                        <div className="mb-3">
                                                            <Label htmlFor="JoiningdatInput" className="form-label">{t('Joining Date')}</Label>
                                                            <Input
                                                                name="joining_date"
                                                                className="form-control"
                                                                disabled
                                                                value={formik?.values?.joining_date || ''}
                                                            />
                                                        </div>
                                                    </Col>
                                                    {/* <Col lg={12}>
                                                        <div className="hstack gap-2 justify-content-end">
                                                            <button
                                                                type="submit"
                                                                disabled
                                                                className="btn btn-primary fs-14">
                                                                {isLoading ? <Spinner size="sm me-2" ></Spinner> : (<i className="ri-login-circle-line align-bottom me-2 "></i>)}
                                                                {t('Button Update Profile')}
                                                            </button>
                                                        </div>
                                                    </Col> */}
                                                </Row>
                                            </form>
                                        </TabPane>

                                        <TabPane tabId="2">
                                            <form onSubmit={formikChangePassword.handleSubmit}>
                                                <Row className="g-2">
                                                    <Col lg={4}>
                                                        <div>
                                                            <Label htmlFor="oldpasswordInput" className="form-label">{t('Old Password')} <span className="text-danger"> *</span></Label>
                                                            <Input
                                                                name="old_password"
                                                                type="password"
                                                                className="form-control"
                                                                id="oldpasswordInput"
                                                                placeholder={t('Old Password')}
                                                                value={formikChangePassword?.values?.old_password}
                                                                onChange={(event: any) => formikChangePassword.setFieldValue('old_password', event?.target?.value)}
                                                                disabled
                                                            />
                                                            {formikChangePassword.touched.old_password && formikChangePassword.errors.old_password ? (
                                                                <div className="text-danger mt-2">{formikChangePassword.errors.old_password}</div>
                                                            ) : null}
                                                        </div>
                                                    </Col>

                                                    <Col lg={4}>
                                                        <div>
                                                            <Label htmlFor="newpasswordInput" className="form-label">{t('New Password')} <span className="text-danger"> *</span></Label>
                                                            <Input
                                                                name="new_password"
                                                                type="password"
                                                                className="form-control"
                                                                id="newpasswordInput"
                                                                placeholder={t('New Password')}
                                                                value={formikChangePassword?.values?.new_password}
                                                                onChange={(event: any) => formikChangePassword.setFieldValue('new_password', event?.target?.value)}
                                                                disabled
                                                            />
                                                            {formikChangePassword.touched.new_password && formikChangePassword.errors.new_password ? (
                                                                <div className="text-danger mt-2">{formikChangePassword.errors.new_password}</div>
                                                            ) : null}
                                                        </div>
                                                    </Col>

                                                    <Col lg={4}>
                                                        <div>
                                                            <Label htmlFor="confirmpasswordInput" className="form-label">{t('Confirm Password')} <span className="text-danger"> *</span></Label>
                                                            <Input
                                                                name="confirm_password"
                                                                type="password"
                                                                className="form-control"
                                                                id="confirmpasswordInput"
                                                                placeholder={t('Confirm Password')}
                                                                value={formikChangePassword?.values?.confirm_password}
                                                                onChange={(event: any) => formikChangePassword.setFieldValue('confirm_password', event?.target?.value)}
                                                                disabled
                                                            />
                                                            {formikChangePassword.touched.confirm_password && formikChangePassword.errors.confirm_password ? (
                                                                <div className="text-danger mt-2">{formikChangePassword.errors.confirm_password}</div>
                                                            ) : null}
                                                        </div>
                                                    </Col>

                                                    {/* <Col lg={12}>
                                                        <div className="mb-3">
                                                            <Link to="#"
                                                                className="link-primary text-decoration-underline">Forgot
                                                                Password ?</Link>
                                                        </div>
                                                    </Col> */}

                                                    <Col lg={12}>
                                                        <div className="text-end">
                                                            <button type="submit" className="btn btn-primary" disabled>
                                                                {isLoading && <Spinner size="sm me-2" ></Spinner>}
                                                                {t('Change Password')}
                                                            </button>
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </form>
                                            {/* <div className="mt-4 mb-3 border-bottom pb-2">
                                                <div className="float-end">
                                                    <Link to="#" className="link-secondary">{t('All Logout')}</Link>
                                                </div>
                                                <h5 className="card-title">{t('Login History')}</h5>
                                            </div>
                                            <div className="d-flex align-items-center mb-3">
                                                <div className="flex-shrink-0 avatar-sm">
                                                    <div className="avatar-title bg-light text-primary rounded-3 fs-18">
                                                        <i className="ri-smartphone-line"></i>
                                                    </div>
                                                </div>
                                                <div className="flex-grow-1 ms-3">
                                                    <h6>iPhone 12 Pro</h6>
                                                    <p className="text-muted mb-0">Los Angeles, United States - March 16 at
                                                        2:47PM</p>
                                                </div>
                                                <div>
                                                    <Link to="#">Logout</Link>
                                                </div>
                                            </div> */}

                                        </TabPane>

                                        <TabPane tabId="3">
                                        </TabPane>
                                        <TabPane tabId="4">
                                        </TabPane>
                                    </TabContent >
                                </CardBody >
                            </Card >
                        </Col >
                    </Row >
                </Container >
            </div>
            <ToastContainer closeButton={false} limit={1} />

        </React.Fragment >
    );
};

export default Profile;
